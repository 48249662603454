import { ColumnsType } from "antd/es/table";
import { TableDataType } from "./types";
import { Link } from "react-router-dom";
import { Tag } from "antd";
import React from "react";

export const columnCampaignType: ColumnsType<TableDataType> = [
  {
    title: <span className="dragHandler">id</span>,
    dataIndex: "id",
    key: "id",
    width: 100,
  },
  {
    title: <span className="dragHandler">Name</span>,
    dataIndex: "name",
    key: "name",
    width: 150,
    render: (text, column) => (
      <Link to={`/campaigns/${column.key}/ads`}>{text}</Link>
    ),
  },
  {
    title: <span className="dragHandler">Status</span>,
    dataIndex: "status",
    key: "status",
    width: 150,
    filters: [
      {
        text: "Running",
        value: "RUNNING",
      },
      {
        text: "Paused",
        value: "PAUSED",
      },
      {
        text: "Rejected",
        value: "REJECTED",
      },
      {
        text: "Pending Approval",
        value: "PENDING_APPROVAL",
      },
      {
        text: "Campaign on air",
        value: "CAMPAIGN_ON_AIR",
      },
      {
        text: "Campaign disabled",
        value: "CAMPAIGN_DISABLED",
      },
      {
        text: "Campaign not in scheduling hours",
        value: "CAMPAIGN_NOT_IN_SCHEDULING_HOURS",
      },
    ],
    onFilter: (value: any, record) => record.status.indexOf(value) === 0,
    render: (_, { status }) => {
      let color;
      switch (status) {
        case "PAUSED":
        case "CAMPAIGN_DISABLED":
          color = "orange";
          break;
        case "REJECTED":
          color = "red";
          break;
        case "CAMPAIGN_ON_AIR":
        case "RUNNING":
          color = "green";
          break;
        default:
          color = "blue";
          break;
      }
      return (
        <>
          <Tag
            color={
              status === "PAUSED"
                ? color
                : status === "REJECTED"
                ? color
                : color
            }
            key={status}
          >
            {status.toUpperCase() !== "CAMPAIGN_NOT_IN_SCHEDULING_HOURS"
              ? status.toUpperCase()
              : "NOT_IN_SCHEDULING"}
          </Tag>
        </>
      );
    },
  },
  {
    title: <span className="dragHandler">Budget</span>,
    dataIndex: "budget",
    key: "budget",
    width: 100,
    sorter: (a, b) => (a.budget || 0) - (b.budget || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { budget }) => <>{"£" + budget}</>,
  },
  {
    title: <span className="dragHandler">Daily Budget</span>,
    dataIndex: "dailyBudget",
    key: "dailyBudget",
    width: 100,
    sorter: (a, b) => (a.dailyBudget || 0) - (b.dailyBudget || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { dailyBudget }) => <>{"£" + dailyBudget}</>,
  },
  {
    title: <span className="dragHandler">CPC</span>,
    dataIndex: "cpc",
    key: "cpc",
    width: 100,
    sorter: (a, b) => (a.cpc || 0) - (b.cpc || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { cpc }) => <>{"£" + cpc}</>,
  },
  {
    title: <span className="dragHandler">Target CPA</span>,
    dataIndex: "targetCpa",
    key: "targetCpa",
    width: 100,
    sorter: (a, b) => (a.targetCpa || 0) - (b.targetCpa || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { targetCpa }) => <>{"£" + targetCpa}</>,
  },
  {
    title: <span className="dragHandler">Impressions</span>,
    dataIndex: "impressions",
    key: "impressions",
    width: 100,
    sorter: (a, b) => (a.impressions || 0) - (b.impressions || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { impressions }) => <>{impressions}</>,
  },
  {
    title: <span className="dragHandler">Visible Impressions</span>,
    dataIndex: "visibleImpressions",
    key: "visibleImpressions",
    width: 100,
    sorter: (a, b) => (a.visibleImpressions || 0) - (b.visibleImpressions || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { visibleImpressions }) => <>{visibleImpressions}</>,
  },
  {
    title: <span className="dragHandler">Spent</span>,
    dataIndex: "spent",
    key: "spent",
    width: 100,
    sorter: (a, b) => (a.spent || 0) - (b.spent || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { spent }) => <>{spent ? "£" + spent.toFixed(4) : ""}</>,
  },
  {
    title: <span className="dragHandler">Clicks</span>,
    dataIndex: "clicks",
    key: "clicks",
    width: 100,
    sorter: (a, b) => (a.clicks || 0) - (b.clicks || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { clicks }) => <>{clicks ?? ""}</>,
  },
  {
    title: <span className="dragHandler">CTR</span>,
    dataIndex: "ctr",
    key: "ctr",
    width: 100,
    sorter: (a, b) => (a.ctr || 0) - (b.ctr || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { ctr }) => <>{ctr ? ctr.toFixed(4) + "%" : ""}</>,
  },
  {
    title: <span className="dragHandler">VCTR</span>,
    dataIndex: "vctr",
    key: "vctr",
    width: 100,
    sorter: (a, b) => (a.vctr || 0) - (b.vctr || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { vctr }) => <>{vctr ? vctr.toFixed(4) + "%" : ""}</>,
  },
  {
    title: <span className="dragHandler">Actual CPC</span>,
    dataIndex: "adCpc",
    key: "adCpc",
    width: 100,
    sorter: (a, b) => (a.adCpc || 0) - (b.adCpc || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { adCpc }) => <>{adCpc ? "£" + adCpc.toFixed(4) : ""}</>,
  },
  {
    title: <span className="dragHandler">CPA</span>,
    dataIndex: "cpa",
    key: "cpa",
    width: 100,
    sorter: (a, b) => (a.cpa || 0) - (b.cpa || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { cpa }) => <>{cpa ? "£" + cpa.toFixed(4) : ""}</>,
  },
  {
    title: <span className="dragHandler">CPM</span>,
    dataIndex: "cpm",
    key: "cpm",
    width: 100,
    sorter: (a, b) => (a.cpm || 0) - (b.ctr || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { cpm }) => <>{cpm ? "£" + cpm.toFixed(4) : ""}</>,
  },
  {
    title: <span className="dragHandler">CVR</span>,
    dataIndex: "cvr",
    key: "cvr",
    width: 100,
    sorter: (a, b) => (a.cvr || 0) - (b.cvr || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { cvr }) => <>{cvr ? cvr.toFixed(4) + "%" : ""}</>,
  },
  {
    title: <span className="dragHandler">VCPM</span>,
    dataIndex: "vcpm",
    key: "vcpm",
    width: 100,
    sorter: (a, b) => (a.vcpm || 0) - (b.vcpm || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { vcpm }) => <>{vcpm ? "£" + vcpm.toFixed(4) : ""}</>,
  },
  {
    title: <span className="dragHandler">ROAS</span>,
    dataIndex: "roas",
    key: "roas",
    width: 100,
    sorter: (a, b) => (a.roas || 0) - (b.roas || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { roas }) => <>{roas ? roas.toFixed(4) + "%" : ""}</>,
  },
  {
    title: <span className="dragHandler">Conversions with upsells</span>,
    dataIndex: "conversionsWithUpsells",
    key: "conversionsWithUpsells",
    width: 100,
    sorter: (a, b) =>
      (a.conversionsWithUpsells || 0) - (b.conversionsWithUpsells || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { conversionsWithUpsells }) => (
      <>{conversionsWithUpsells ? conversionsWithUpsells : ""}</>
    ),
  },
  {
    title: <span className="dragHandler">Conversions no upsell</span>,
    dataIndex: "conversionsNoUpsell",
    key: "conversionsNoUpsell",
    width: 100,
    sorter: (a, b) =>
      (a.conversionsNoUpsell || 0) - (b.conversionsNoUpsell || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { conversionsNoUpsell }) => (
      <>{conversionsNoUpsell ? conversionsNoUpsell : ""}</>
    ),
  },
  {
    title: <span className="dragHandler">Revenue with upsell</span>,
    dataIndex: "revenueWithUpsell",
    key: "revenueWithUpsell",
    width: 100,
    sorter: (a, b) => (a.revenueWithUpsell || 0) - (b.revenueWithUpsell || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { revenueWithUpsell }) => (
      <>{revenueWithUpsell ? "£" + revenueWithUpsell.toFixed(4) : ""}</>
    ),
  },
  {
    title: <span className="dragHandler">Revenue no upsells</span>,
    dataIndex: "revenueNoUpsells",
    key: "revenueNoUpsells",
    width: 100,
    sorter: (a, b) => (a.revenueNoUpsells || 0) - (b.revenueNoUpsells || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { revenueNoUpsells }) => (
      <>{revenueNoUpsells ? "£" + revenueNoUpsells.toFixed(4) : ""}</>
    ),
  },
  {
    title: <span className="dragHandler">Lander views</span>,
    dataIndex: "landerViews",
    key: "landerViews",
    width: 100,
    sorter: (a, b) => (a.landerViews || 0) - (b.landerViews || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { landerViews }) => <>{landerViews ? landerViews : ""}</>,
  },
  {
    title: <span className="dragHandler">Lander clicks</span>,
    dataIndex: "landerClicks",
    key: "landerClicks",
    width: 100,
    sorter: (a, b) => (a.landerClicks || 0) - (b.landerClicks || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { landerClicks }) => <>{landerClicks ? landerClicks : ""}</>,
  },
  {
    title: <span className="dragHandler">Lander CTR</span>,
    dataIndex: "landerCtr",
    key: "landerCtr",
    width: 100,
    sorter: (a, b) => (a.landerCtr || 0) - (b.landerCtr || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { landerCtr }) => (
      <>{landerCtr ? landerCtr.toFixed(4) + "%" : ""}</>
    ),
  },
];
