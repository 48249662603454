import React, { useState } from "react";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { IAdsTable } from "../../types";
import DragColumn from "../../../components/DragColumn";

const AdsTable: React.FC<{
  dataSource: IAdsTable[];
  loading: boolean;
  column: ColumnsType<IAdsTable>;
}> = ({ dataSource, loading, column }) => {
  const [tableParams, setTableParams] = useState<{
    pagination?: TablePaginationConfig;
  }>({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });
  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination,
    });
  };

  return (
    <DragColumn
      initialColumnState={column}
      tableProps={{
        ...(column.length >= 6
          ? {
              scroll: {
                x: column.length * 100,
                y: 700,
              },
            }
          : {}),
        sticky: true,
        loading: loading,
        size: "small",
        columns: column,
        dataSource: dataSource,
        onChange: handleTableChange,
        pagination: tableParams.pagination,
      }}
    />
  );
};

export default AdsTable;
