import React, { useEffect, useState } from "react";
import { Col, Row, Select, Tabs } from "antd";
import {
  ColumnNameEnum,
  CreatePresetInterface,
  GetPresetInterface,
  IAdsTable,
  RequestFilterInterface,
} from "../../types";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import AdsTable from "./adsTable";
import { campaignDetailApi } from "../../../../redux/services/campaignDetailApi";
import "./index.css";
import { getTodayFormatted } from "../../../../utils";
import FilterInput from "../filterInput";
import DatePickerComponent from "../datePicker";
import CreatePreset from "../../../presets";
import { columnAdsType } from "./columnType";
import { campaignsApi } from "../../../../redux/services/campaignsApi";
import { ColumnType } from "antd/es/list";

const { TabPane } = Tabs;

const Ads = () => {
  const [tableData, setTableData] = useState<IAdsTable[]>([]);
  const [date, setDate] = useState(String(getTodayFormatted()));
  const [dateTo, setDateTo] = useState(String(getTodayFormatted()));
  const [filterValue, setFilterValue] = useState<string>("");
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [presets, setPresets] = useState<GetPresetInterface[]>([]);
  const [getPresets, { data: presetsData, status: getPresetStatus }] =
    campaignsApi.useLazyGetPresetsQuery();

  const [fetchCampaignAds, { data, status }] =
    campaignDetailApi.useLazyFetchCampaignAdsQuery();
  const [getCurrentUser, { data: CurrentUser }] =
    campaignsApi.useLazyGetCurrentUserQuery();
  const [searchValue, setSearchValue] = useState<string>();
  const [currentPreset, setCurrentPreset] = useState<CreatePresetInterface>();
  const onChangeSelectedPreset = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    setCurrentPreset(presets.find((item) => item.name == searchValue));
  }, [searchValue]);

  useEffect(() => {
    if (!presetsData) return;
    setPresets(presetsData);
  }, [presetsData]);

  useEffect(() => {
    fetchCampaignAds({
      id: params?.id || "",
      date: date,
      date_to: dateTo,
      filterValue: filterValue,
    })
      .unwrap()
      .catch((err) => {
        if (err && err.status === 404) {
          navigate("/campaigns");
        }
      });
    getPresets("AdTable");
    getCurrentUser(undefined);
  }, [date, dateTo]);

  useEffect(() => {
    if (data) {
      const adsData = data?.map((ad) => ({
        status: ad.AD.status,
        key: ad.AD.externalId,
        id: ad.AD.externalId,
        title: ad.AD.title,
        description: ad.AD.description,
        approvalState: ad.AD.approvalState,
        thumbnailUrl: ad.AD.thumbnailUrl,
        cta: ad.AD.cta,
        url: ad.AD.url,
        bucketUrl: ad.bucketUrl ?? ad.AD.thumbnailUrl,
        impressions: ad.impressions ?? 0,
        visibleImpressions: ad.visibleImpressions ?? 0,
        ctr: ad.ctr ?? 0,
        vctr: ad.vctr ?? 0,
        clicks: ad.clicks ?? 0,
        cpc: ad.cpc ?? 0,
        cvr: ad.cvr ?? 0,
        cpa: ad.cpa || 0,
        actions: ad.actions ?? 0,
        actionsNumFromClicks: ad.actionsNumFromClicks ?? 0,
        actionsNumFromViews: ad.actionsNumFromViews ?? 0,
        cpm: ad.cpm ?? 0,
        vcpm: ad.vcpm ?? 0,
        spent: ad.spent ?? 0,
        roas: ad.roas ?? 0,
        cvrClicks: ad.cvrClicks ?? null,
        cvrViews: ad.cvrViews ?? null,
        currency: ad.currency ?? null,
        revenueWithUpsell: ad.revenueWithUpsell ?? null,
        conversionsWithUpsells: ad.conversionsWithUpsells ?? null,
        revenueNoUpsells: ad.revenueNoUpsells || null,
        conversionsNoUpsell: ad.conversionsNoUpsell || null,
        cost: ad.cost || null,
        cpaClicks: ad.cpaClicks ?? null,
        cpaViews: ad.cpaViews ?? null,
        conversionsValue: ad.conversionsValue ?? null,
        landerViews: ad.landerViews ?? null,
        landerClicks: ad.landerClicks ?? null,
        landerCtr: ad.landerCtr ?? null,
        updatedAt: ad.createdAt ?? null,
      }));
      setTableData(adsData);
    }
  }, [data]);

  let column = columnAdsType;

  const handleTabChange = (key: string) => {
    if (key === "1") {
      navigate(`/campaigns/${params.id}/ads`);
    } else if (key === "2") {
      navigate(`/campaigns/${params.id}/publishers`);
    } else if (key == "3") {
      navigate(`/campaigns/${params.id}/sections`);
    }
  };

  const [rangePickerDate, setRangePickerDate] = useState();

  const onChangeDateFrom = (date: string) => {
    setDate(date);
  };

  const onChangeDateTo = (date: string) => {
    setDateTo(date);
  };

  const onChangeRangePickerDate = (arg: any) => {
    setRangePickerDate(arg);
  };

  const onChangeFilterInput = (obj: RequestFilterInterface) => {
    fetchCampaignAds({
      id: obj.id,
      date: obj.date,
      date_to: obj.date_to,
      filterValue: obj.filterValue,
    });
    setFilterValue(obj.filterValue ?? "");
  };

  const [columnList, setColumnList] = useState<
    Array<{ label: string; value: string }>
  >(() => {
    return column.map((item) => {
      const spanElement = React.Children.only(item.title) as React.ReactElement;
      const label = spanElement.props.children;
      const key = typeof item.key === "string" ? item.key : String(item.key);
      return {
        label: label,
        value: key,
      };
    });
  });

  const [columnState, setColumnState] = useState(column);

  useEffect(() => {
    if (presets[0]) {
      setCurrentPreset(presets[0]);
    } else {
      setCurrentPreset(undefined);
    }
  }, [presets]);

  useEffect(() => {
    if (!column) {
      return;
    }

    if (presets.length === 0) {
      setColumnState(column);
      return;
    }

    const columnSettingsArray = JSON.parse(
      currentPreset?.column_settings || "[]",
    );
    const columnKeyMap: Record<string, any> = {};
    column.forEach((col) => {
      const key = String(col.key ?? "");
      columnKeyMap[key] = key;
    });

    const newColumnOrder = columnSettingsArray
      .map((setting: { value: string }) => columnKeyMap[setting.value])
      .filter(
        (col: ColumnType | undefined | null) =>
          col !== undefined && col !== null,
      );

    const filteredColumns = newColumnOrder.map((columnName: string) => {
      return column.find((col) => col.key === columnName);
    });

    setColumnState(filteredColumns);
  }, [currentPreset, presets]);

  const handleRefetchPresets = () => {
    getPresets("AdTable");
  };

  return (
    <div style={{ position: "relative" }}>
      <Row gutter={[16, 16]} justify="end">
        <Col span={24}>
          <Tabs
            animated={false}
            activeKey={location.pathname.includes("publishers") ? "2" : "1"}
            onChange={handleTabChange}
            style={{
              marginTop: "35px",
            }}
          >
            <TabPane tab="Ads" key="1">
              <AdsTable
                loading={getPresetStatus !== "fulfilled"}
                dataSource={tableData}
                column={columnState}
              />
            </TabPane>
            <TabPane tab="Publishers" key="2"></TabPane>
            <TabPane tab="Sections" key="3"></TabPane>
          </Tabs>
        </Col>
      </Row>
      <div style={{ position: "absolute", top: 0, right: 16 }}>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "5px", minWidth: "270px" }}>
            <DatePickerComponent
              onChangeDateFrom={onChangeDateFrom}
              onChangeDateTo={onChangeDateTo}
              onChangeRangePicker={onChangeRangePickerDate}
              setRangePickerDate={setRangePickerDate}
              rangePickerDate={rangePickerDate}
              dateFrom={date}
              dateTo={dateTo}
            />
          </div>
          <FilterInput
            onFilterChange={onChangeFilterInput}
            campaignId={params?.id}
            date={date}
            dateTo={dateTo}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div></div>
          <div style={{ display: "flex" }}>
            {CurrentUser && CurrentUser.role.name == "admin" && (
              <div style={{ marginRight: "5px" }}>
                <CreatePreset
                  tableName={"AdTable" as ColumnNameEnum}
                  columns={columnList}
                  presets={presets}
                  trigger={handleRefetchPresets}
                />
              </div>
            )}
            <Select
              showSearch
              placeholder="Select a preset"
              style={{ minWidth: "145px", width: "145px" }}
              onChange={onChangeSelectedPreset}
              value={currentPreset?.name}
              options={presets.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ads;
