import { ColumnsType } from "antd/es/table";
import { IAdsTable } from "../../types";
import { Link } from "react-router-dom";
import { Tag } from "antd";
import React from "react";

export const columnAdsType: ColumnsType<IAdsTable> = [
  {
    title: <span className="dragHandler">id</span>,
    dataIndex: "id",
    key: "id",
    width: 100,
  },
  {
    title: <span className="dragHandler">Title</span>,
    dataIndex: "title",
    key: "title",
    render: (_, { url, title }) => (
      <span>
        {url && (
          <Link target="_blank" to={url}>
            {title}
          </Link>
        )}
      </span>
    ),
    width: 200,
  },
  {
    title: <span className="dragHandler">Status</span>,
    dataIndex: "status",
    key: "status",
    render: (_, { status }) => (
      <>
        <Tag
          color={
            status === "PAUSED"
              ? "orange"
              : status === "REJECTED"
              ? "red"
              : "green"
          }
          key={status}
        >
          {status.toUpperCase()}
        </Tag>
      </>
    ),
    width: 180,
  },
  {
    title: <span className="dragHandler">Approval State</span>,
    dataIndex: "approvalState",
    key: "approvalState",
    render: (_, { approvalState }) => (
      <>
        <Tag
          color={
            approvalState === "APPROVED"
              ? "green"
              : approvalState === "REJECTED"
              ? "red"
              : "orange"
          }
          key={approvalState}
        >
          {approvalState?.toUpperCase()}
        </Tag>
      </>
    ),
    width: 100,
  },
  {
    title: <span className="dragHandler">Description</span>,
    dataIndex: "description",
    key: "description",
    width: 100,
  },

  {
    title: <span className="dragHandler">Thumbnail Url</span>,
    dataIndex: "bucketUrl",
    key: "bucketUrl",
    render: (_, { bucketUrl }) => (
      <span>
        <img
          style={{ objectFit: "cover" }}
          width={100}
          height={70}
          src={bucketUrl}
          alt={bucketUrl}
        />
      </span>
    ),
    width: 150,
  },
  {
    title: <span className="dragHandler">CTA</span>,
    dataIndex: "cta",
    key: "cta",
    sorter: (a, b) => {
      if (a.cta === null && b.cta === null) return 0;
      if (a.cta === null) return -1;
      if (b.cta === null) return 1;
      return a.cta.localeCompare(b.cta);
    },
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Impressions</span>,
    dataIndex: "impressions",
    key: "impressions",
    sorter: (a, b) => (a.impressions || 0) - (b.impressions || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Visible Impressions</span>,
    dataIndex: "visibleImpressions",
    key: "visibleImpressions",
    sorter: (a, b) => (a.visibleImpressions || 0) - (b.visibleImpressions || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CTR</span>,
    dataIndex: "ctr",
    key: "ctr",
    sorter: (a, b) => (a.ctr || 0) - (b.ctr || 0),
    render: (_, { ctr }) => <>{ctr ? ctr.toFixed(4) + "%" : ""}</>,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">VCTR</span>,
    dataIndex: "vctr",
    key: "vctr",
    sorter: (a, b) => (a.vctr || 0) - (b.vctr || 0),
    render: (_, { vctr }) => <>{vctr ? vctr.toFixed(4) + "%" : ""}</>,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Clicks</span>,
    dataIndex: "clicks",
    key: "clicks",
    sorter: (a, b) => (a.clicks || 0) - (b.clicks || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPC</span>,
    dataIndex: "cpc",
    key: "cpc",
    sorter: (a, b) => (a.cpc || 0) - (b.cpc || 0),
    render: (_, { cpc }) => <>{cpc ? "£" + cpc.toFixed(4) : ""}</>,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CVR</span>,
    dataIndex: "cvr",
    key: "cvr",
    sorter: (a, b) => (a.cvr || 0) - (b.cvr || 0),
    render: (_, { cvr }) => <>{cvr ? cvr.toFixed(4) + "%" : ""}</>,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CVR Clicks</span>,
    dataIndex: "cvrClicks",
    key: "cvrClicks",
    sorter: (a, b) => (a.cvrClicks || 0) - (b.cvrClicks || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CVR Views</span>,
    dataIndex: "cvrViews",
    key: "cvrViews",
    sorter: (a, b) => (a.cvrViews || 0) - (b.cvrViews || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPA</span>,
    dataIndex: "cpa",
    key: "cpa",
    sorter: (a, b) => (a.cpa || 0) - (b.cpa || 0),
    render: (_, { cpa }) => <>{cpa ? "£" + cpa.toFixed(4) : ""}</>,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPA Clicks</span>,
    dataIndex: "cpaClicks",
    key: "cpaClicks",
    sorter: (a, b) => (a.cpaClicks || 0) - (b.cpaClicks || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPA Views</span>,
    dataIndex: "cpaViews",
    key: "cpaViews",
    sorter: (a, b) => (a.cpaViews || 0) - (b.cpaViews || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Actions</span>,
    dataIndex: "actions",
    key: "actions",
    sorter: (a, b) => a.actions - b.actions,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Actions Num From Clicks</span>,
    dataIndex: "actionsNumFromClicks",
    key: "actionsNumFromClicks",
    sorter: (a, b) => a.actionsNumFromClicks - b.actionsNumFromClicks,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Actions Num From Views</span>,
    dataIndex: "actionsNumFromViews",
    key: "actionsNumFromViews",
    sorter: (a, b) => a.actionsNumFromViews - b.actionsNumFromViews,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPM</span>,
    dataIndex: "cpm",
    key: "cpm",
    sorter: (a, b) => a.cpm - b.cpm,
    render: (_, { cpm }) => <>{cpm ? "£" + cpm.toFixed(4) : ""}</>,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">VCPM</span>,
    dataIndex: "vcpm",
    key: "vcpm",
    sorter: (a, b) => a.vcpm - b.vcpm,
    render: (_, { vcpm }) => <>{vcpm ? "£" + vcpm.toFixed(4) : ""}</>,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Spent</span>,
    dataIndex: "spent",
    key: "spent",
    sorter: (a, b) => a.spent - b.spent,
    render: (_, { spent }) => <>{spent !== null ? "£" + spent : null}</>,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Conversions Value</span>,
    dataIndex: "conversionsValue",
    key: "conversionsValue",
    sorter: (a, b) => (a.conversionsValue || 0) - (b.conversionsValue || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Roas</span>,
    dataIndex: "roas",
    key: "roas",
    sorter: (a, b) => a.roas - b.roas,
    render: (_, { roas }) => (
      <>{roas !== null ? (roas === 0 ? 0 : roas.toFixed(4) + "%") : ""}</>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Currency</span>,
    dataIndex: "currency",
    key: "currency",
    width: 100,
  },
  {
    title: <span className="dragHandler">Revenue With Upsell</span>,
    dataIndex: "revenueWithUpsell",
    key: "revenueWithUpsell",
    sorter: (a, b) => (a.revenueWithUpsell || 0) - (b.revenueWithUpsell || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Conversions With Upsells</span>,
    dataIndex: "conversionsWithUpsells",
    key: "conversionsWithUpsells",
    sorter: (a, b) =>
      (a.conversionsWithUpsells || 0) - (b.conversionsWithUpsells || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Revenue No Upsells</span>,
    dataIndex: "revenueNoUpsells",
    key: "revenueNoUpsells",
    sorter: (a, b) => (a.revenueNoUpsells || 0) - (b.revenueNoUpsells || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Conversions No Upsell</span>,
    dataIndex: "conversionsNoUpsell",
    key: "conversionsNoUpsell",
    sorter: (a, b) =>
      (a.conversionsNoUpsell || 0) - (b.conversionsNoUpsell || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Cost</span>,
    dataIndex: "cost",
    key: "cost",
    sorter: (a, b) => (a.cost || 0) - (b.cost || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { cost }) => (
      <>{cost !== null ? (cost === 0 ? 0 : cost?.toFixed(3)) : null}</>
    ),
    width: 100,
  },
  {
    title: <span className="dragHandler">Lander Views</span>,
    dataIndex: "landerViews",
    key: "landerViews",
    sorter: (a, b) => (a.landerViews || 0) - (b.landerViews || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Lander Clicks</span>,
    dataIndex: "landerClicks",
    key: "landerClicks",
    sorter: (a, b) => (a.landerClicks || 0) - (b.landerClicks || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Lander CTR</span>,
    dataIndex: "landerCtr",
    key: "landerCtr",
    sorter: (a, b) => (a.landerCtr || 0) - (b.landerCtr || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { landerCtr }) => (
      <>
        {landerCtr !== null
          ? landerCtr === 0
            ? 0
            : landerCtr?.toFixed(3) + "%"
          : null}
      </>
    ),
    width: 100,
  },
  {
    title: <span className="dragHandler">Report updated</span>,
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (text: string) => {
      const date = text ? new Date(text) : "";
      return <div>{date ? date.toLocaleString() : ""}</div>;
    },
    width: 100,
  },
];
