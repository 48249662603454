import { IDataSourceById, IInitialValues } from "./types";
import { sourceApi } from "../../redux/services/sourceApi";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SourceUpdateForm from "./updateSourceForm";

const SourceUpdatePage = () => {
  const params = useParams();
  const [sourceData, setSourceData] = useState<IDataSourceById | undefined>();
  const { refetch } = sourceApi.useFetchSourceByIdQuery(Number(params?.id));
  useEffect(() => {
    if (params) {
      refetch().then(
        (response) => response.isSuccess && setSourceData(response.data),
      );
    }
  }, [params]);

  const [initialValues, setInitialValues] = useState<IInitialValues>();

  useEffect(() => {
    if (sourceData) {
      setInitialValues({
        name: sourceData.name,
        apiKey: sourceData.funnelFlux ? sourceData.funnelFlux.apiKey : "",
        url: sourceData.funnelFlux ? sourceData.funnelFlux.url : "",
      });
    }
  }, [sourceData]);

  return (
    <div>
      {initialValues && <SourceUpdateForm initialValues={initialValues} />}
    </div>
  );
};

export default SourceUpdatePage;
