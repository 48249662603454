import React, { useEffect, useState } from "react";
import {
  ColumnNameEnum,
  CreatePresetInterface,
  GetPresetInterface,
  RequestFilterInterface,
  TableDataType,
} from "./types";
import CampaignTable from "./campaignTable";
import { campaignsApi } from "../../redux/services/campaignsApi";
import { Button, Col, Popover, Row, Select, Tree, TreeProps } from "antd";
import FilterInput from "./campaignTable/filterInput";
import { getTodayFormatted } from "../../utils";
import DatePickerComponent from "./campaignTable/datePicker";
import { FilterOutlined } from "@ant-design/icons";
import { IDataSource } from "../account/types";
import type { DataNode } from "antd/es/tree";
import { sourceApi } from "../../redux/services/sourceApi";
import CreatePreset from "../presets";
import { ColumnType } from "antd/es/list";
import { columnCampaignType } from "./columnType";

const Campaigns = () => {
  const [tableData, setTableData] = useState<TableDataType[]>([]);
  const [fetchCampaigns, { data }] =
    campaignsApi.useLazyFetchAllCampaignsQuery();
  const { refetch } = sourceApi.useFetchSourcesQuery("");
  const [date, setDate] = useState(String(getTodayFormatted()));
  const [dateTo, setDateTo] = useState(String(getTodayFormatted()));
  const [rangePickerDate, setRangePickerDate] = useState();
  const [filterValue, setFilterValue] = useState<string>("");
  const [accountSortList, setAccountSortList] = useState<string>();
  const [source, setDataSource] = useState<IDataSource[]>([]);
  const [presets, setPresets] = useState<GetPresetInterface[]>([]);
  const [getPresets, { data: presetsData, status: getPresetStatus }] =
    campaignsApi.useLazyGetPresetsQuery();

  const { data: user } = campaignsApi.useGetUserQuery();
  useEffect(() => {
    fetchCampaigns({
      date_from: date,
      date_to: dateTo,
      filterValue: filterValue,
      accountId: accountSortList,
    });
  }, [date, dateTo, accountSortList]);

  useEffect(() => {
    refetch().then(
      (response) => response.isSuccess && setDataSource(response.data),
    );
    getPresets("CampaignTable");
  }, []);

  useEffect(() => {
    if (!presetsData) return;
    setPresets(presetsData);
  }, [presetsData]);

  useEffect(() => {
    if (data) {
      const tableData = data.map((campaign) => ({
        key: campaign?.id.toString(),
        accountId: campaign.accountId.toString(),
        name: campaign?.name,
        status: campaign?.status,
        dailyBudget: campaign?.dailyBudget,
        cpc: campaign?.cpc,
        targetCpa: campaign?.targetCpa,
        id: campaign?.externalId,
        budget: campaign?.budget,
        impressions: campaign?.campaignReport?.impressions,
        visibleImpressions: campaign?.campaignReport?.visibleImpressions,
        spent: campaign?.campaignReport?.spent,
        clicks: campaign?.campaignReport?.clicks,
        ctr: campaign?.campaignReport?.ctr,
        vctr: campaign?.campaignReport?.vctr,
        adCpc: campaign?.campaignReport?.cpc,
        cpa: campaign?.campaignReport?.cpa,
        cpm: campaign?.campaignReport?.cpm,
        cvr: campaign?.campaignReport?.cvr,
        vcpm: campaign?.campaignReport?.vcpm,
        roas: campaign?.campaignReport?.roas,
        conversionsWithUpsells:
          campaign?.campaignReport?.conversionsWithUpsells,
        conversionsNoUpsell: campaign?.campaignReport?.conversionsNoUpsell,
        landerViews: campaign?.campaignReport?.landerViews,
        landerClicks: campaign?.campaignReport?.landerClicks,
        actions: campaign?.campaignReport?.actions,
        actionsNumFromClicks: campaign?.campaignReport?.actionsNumFromClicks,
        actionsNumFromViews: campaign?.campaignReport?.actionsNumFromViews,
        revenueWithUpsell: campaign?.campaignReport?.revenueWithUpsell,
        revenueNoUpsells: campaign?.campaignReport?.revenueNoUpsells,
        landerCtr: campaign?.campaignReport?.landerCtr,
      }));
      setTableData(tableData);
    }
  }, [data]);

  let column = columnCampaignType;

  const onChangeDateFrom = (date: string) => {
    setDate(date);
  };

  const onChangeDateTo = (date: string) => {
    setDateTo(date);
  };

  const onChangeRangePickerDate = (arg: any) => {
    setRangePickerDate(arg);
  };

  const onChangeFilterInput = (obj: RequestFilterInterface) => {
    fetchCampaigns({
      date_from: obj.date,
      date_to: obj.date_to,
      filterValue: obj.filterValue ?? "",
    });
    setFilterValue(obj.filterValue ?? "");
  };

  const generateTreeData = (sourceArr: IDataSource[]): DataNode[] => {
    return sourceArr.map((sourceItem) => {
      return {
        title: sourceItem.name,
        key: `source_${sourceItem.id}`,
        children: sourceItem.account.map((accountItem) => ({
          title: accountItem.externalAccountId,
          key: `${accountItem.id}`,
        })),
      };
    });
  };

  const onCheck: TreeProps["onCheck"] = (checkedKeys, info) => {
    const checkedList = info.checkedNodes
      .map((node) => {
        const numericValue = Number(node.key);
        return !isNaN(numericValue) ? numericValue : null;
      })
      .filter((item) => item !== null);
    const accountSortListString = checkedList.join(",");

    setAccountSortList(accountSortListString);
  };
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const [columnState, setColumnState] = useState(column);
  const [selectedColumns, setSelectedColumns] = useState<Array<string>>(() => {
    return column.map((item) => {
      if (React.isValidElement(item.title)) {
        const spanElement = React.Children.only(
          item.title,
        ) as React.ReactElement;
        return spanElement.props.children;
      }
    });
  });

  const [columnList, setColumnList] = useState<
    Array<{ label: string; value: string }>
  >(() => {
    return column.map((item) => {
      const spanElement = React.Children.only(item.title) as React.ReactElement;
      const label = spanElement.props.children;
      const key = typeof item.key === "string" ? item.key : String(item.key);
      return {
        label: label,
        value: key,
      };
    });
  });

  const fixedTitles = ["id", "Name", "Status"];

  const [columnFixedIndex, setColumnFixedIndex] = useState<
    Array<number | undefined> | undefined
  >([0, 1]);

  useEffect(() => {
    const fixedColumn = columnState
      .map((item, index) => {
        if (React.isValidElement(item.title)) {
          const spanElement = React.Children.only(
            item.title,
          ) as React.ReactElement;
          if (fixedTitles.includes(spanElement.props.children)) {
            return index;
          }
        }
        return undefined;
      })
      .filter((value) => typeof value === "number");

    if (fixedColumn.length > 0) {
      setColumnFixedIndex(fixedColumn);
    } else {
      setColumnFixedIndex(undefined);
    }
  }, [columnState]);

  useEffect(() => {
    if (selectedColumns.length > 0) {
      const filteredColumns = column?.filter((item) => {
        if (React.isValidElement(item.title)) {
          const spanElement = React.Children.only(
            item.title,
          ) as React.ReactElement;
          if (selectedColumns.includes(spanElement.props.children)) {
            return item;
          }
        }
      });
      if (filteredColumns) {
        setColumnState(filteredColumns);
      }
    } else {
      setColumnState([]);
    }
  }, [selectedColumns]);

  const [searchValue, setSearchValue] = useState<string>();
  const [currentPreset, setCurrentPreset] = useState<CreatePresetInterface>();
  const onChangeSelectedPreset = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    setCurrentPreset(presets.find((item) => item.name == searchValue));
  }, [searchValue]);

  useEffect(() => {
    if (!column) {
      return;
    }

    if (presets.length === 0) {
      setColumnState(column);
      return;
    }

    const columnSettingsArray = JSON.parse(
      currentPreset?.column_settings || "[]",
    );
    const columnKeyMap: Record<string, any> = {};
    column.forEach((col) => {
      const key = String(col.key ?? "");
      columnKeyMap[key] = key;
    });

    const newColumnOrder = columnSettingsArray
      .map((setting: { value: string }) => columnKeyMap[setting.value])
      .filter(
        (col: ColumnType | undefined | null) =>
          col !== undefined && col !== null,
      );

    const filteredColumns = newColumnOrder.map((columnName: string) => {
      return column.find((col) => col.key === columnName);
    });

    setColumnState(filteredColumns);
  }, [currentPreset, presets]);

  useEffect(() => {
    if (presets[0]) {
      setCurrentPreset(presets[0]);
    } else {
      setCurrentPreset(undefined);
    }
  }, [presets]);

  const handleRefetchPresets = () => {
    getPresets("CampaignTable");
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={8} offset={0} style={{ marginBottom: "1%" }}>
          <div style={{ marginRight: "5px" }}>
            <DatePickerComponent
              onChangeDateFrom={onChangeDateFrom}
              onChangeDateTo={onChangeDateTo}
              onChangeRangePicker={onChangeRangePickerDate}
              setRangePickerDate={setRangePickerDate}
              rangePickerDate={rangePickerDate}
              dateFrom={date}
              dateTo={dateTo}
            />
          </div>
          <div style={{ display: "flex", marginTop: "5px" }}>
            {user && user.role.name == "admin" && (
              <div style={{ marginRight: "5px" }}>
                <CreatePreset
                  tableName={"CampaignTable" as ColumnNameEnum}
                  columns={columnList}
                  presets={presets}
                  trigger={handleRefetchPresets}
                />
              </div>
            )}
            <Select
              showSearch
              placeholder="Select a preset"
              style={{ minWidth: "145px", width: "145px" }}
              onChange={onChangeSelectedPreset}
              value={currentPreset?.name}
              options={presets.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
            />
          </div>
        </Col>
        <Col span={8} offset={8} style={{ marginBottom: "1%" }}>
          <div style={{ display: "flex" }}>
            <Popover
              content={
                <Tree
                  checkable
                  selectable={false}
                  onCheck={onCheck}
                  defaultExpandAll
                  treeData={generateTreeData(source)}
                />
              }
              title="Choose Source/Account"
              trigger="click"
              placement={"bottom"}
              open={open}
              onOpenChange={handleOpenChange}
            >
              <Button style={{ marginRight: "5px" }}>
                <FilterOutlined /> Filter by Source/Account
              </Button>
            </Popover>
            <div style={{ maxWidth: "150px" }}>
              <FilterInput
                onFilterChange={onChangeFilterInput}
                date={date}
                dateTo={dateTo}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <CampaignTable
            loading={getPresetStatus !== "fulfilled"}
            dataSource={tableData}
            column={columnState || []}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Campaigns;
