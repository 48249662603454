import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {
  CampaignData, CampaignSectionReportResponseSchema,
  IAds,
  IPublisher,
} from "../../../components/campaigns/types";
import { axiosBaseQuery } from "../../../utils/axiosBaseQuery";

export const campaignDetailApi = createApi({
  reducerPath: "campaignDetailApi",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_HOST || "http://localhost:8000",
  }),

  endpoints: (build) => ({
    fetchCampaignAds: build.query<
      IAds[],
      { id: string; date?: string; date_to?: string; filterValue?: string }
    >({
      query: (params) => ({
        url: `/campaigns/${params.id}/ads?date=${params.date}&date_to=${params.date_to}&filter_value=${
          params.filterValue ? params.filterValue : ""
        }`,
        method: "get",
      }),
    }),
    fetchCampaignDetail: build.query<CampaignData, string>({
      query: (id) => ({
        url: `/campaigns/${id}`,
        method: "get",
      }),
    }),
    fetchCampaignPublishers: build.query<
      IPublisher[],
      { id: string; date?: string; date_to?: string; filterValue?: string }
    >({
      query: (params) => ({
        url: `/campaigns/${params.id}/publishers?date=${
          params.date
        }&date_to=${params.date_to}&filter_value=${params.filterValue ? params.filterValue : ""}`,
        method: "get",
      }),
    }),
    fetchCampaignSections: build.query<
        CampaignSectionReportResponseSchema[],
        { id: string; date?: string; date_to?: string; filterValue?: string }
    >({
      query: (params) => ({
        url: `/campaigns/${params.id}/sections?date=${
            params.date
        }&date_to=${params.date_to}&filter_value=${params.filterValue ? params.filterValue : ""}`,
        method: "get",
      }),
    }),
  }),
});
