import { Form, Input, Button, Select, message } from "antd";
import { ICreateSource } from "./types";
import { sourceApi } from "../../redux/services/sourceApi";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { campaignsApi } from "../../redux/services/campaignsApi";
import { CREATE_PERMISSION, UPDATE_PERMISSION } from "../../utils";
import usePermissionRedirect from "../../hooks/usePermissionValidation";

const SourceCreateForm = () => {
  const [createSource, { isSuccess, isError }] =
    sourceApi.useCreateSourceMutation();
  const navigate = useNavigate();
  const { data: user } = campaignsApi.useGetUserQuery();

  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: ICreateSource) => {
    setLoading(true);
    await createSource(values);
  };

  if (isSuccess) {
    messageApi.open({
      type: "success",
      content: "Success",
    });
    setTimeout(() => {
      setLoading(false);
      navigate("/accounts");
    }, 900);
  } else if (isError) {
    messageApi.open({
      type: "error",
      content: "error",
    });
    setTimeout(() => {
      setLoading(false);
    }, 900);
  }

  usePermissionRedirect(user, CREATE_PERMISSION);

  const [isAllowToCreate, setIsAllowToCreate] = useState<boolean>(
    !!user?.role.permission?.find(
      (permission) => permission.name === UPDATE_PERMISSION,
    )?.name,
  );

  useEffect(() => {
    setIsAllowToCreate(
      !!user?.role.permission?.find(
        (permission) => permission.name === UPDATE_PERMISSION,
      )?.name,
    );
  }, [user]);

  return (
    <Form
      name="sourceCreateForm"
      layout="vertical"
      onFinish={onFinish}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
    >
      {contextHolder}
      <Form.Item
        label="Name"
        name="name"
        key="name"
        rules={[
          { required: true, message: "Please enter the name" },
          { min: 2, message: "Name must be at least 2 characters long" },
          { max: 32, message: "Name must not exceed 32 characters" },
        ]}
      >
        <Input disabled={!isAllowToCreate} />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Please enter the Client Id" }]}
        label="Client ID"
        name="clientId"
        key="clientId"
      >
        <Input disabled={!isAllowToCreate} />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Please enter the Client Secret" }]}
        label="Client Secret"
        name="clientSecret"
        key="clientSecret"
      >
        <Input disabled={!isAllowToCreate} />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Please enter the Source Type" }]}
        label="Source Type"
        name="sourceType"
        key="sourceType"
      >
        <Select
          disabled={!isAllowToCreate}
          options={[
            { value: "taboola", label: "Taboola" },
            { value: "outbrain", label: "Outbrain" },
          ]}
        />
      </Form.Item>
      <Form.Item
        rules={[
          { required: true, message: "Please enter the Funnel Flux Api Key" },
        ]}
        label="Funnel Flux Api Key"
        name="api_key"
        key="api_key"
      >
        <Input disabled={!isAllowToCreate} />
      </Form.Item>
      <Form.Item
        rules={[
          { required: true, message: "Please enter the Funnel Flux Url" },
        ]}
        label="Funnel Flux Url"
        name="url"
        key="url"
      >
        <Input disabled={!isAllowToCreate} />
      </Form.Item>
      <Form.Item>
        <Button
          loading={loading}
          disabled={!isAllowToCreate}
          type="primary"
          htmlType="submit"
        >
          Create Source
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SourceCreateForm;
