import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  DatePicker,
  Checkbox,
  message,
  Row,
  Col,
} from "antd";
import { rulesApi } from "../../../redux/services/rulesApi";
import { campaignsApi } from "../../../redux/services/campaignsApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RulesForm } from "../types";
import { MultiSelect } from "react-multi-select-component";
import "./index.css";
import { CloseOutlined } from "@ant-design/icons";
import { GetCurrentUserInterface } from "../../campaigns/types";
import { RULE_PERMISSION } from "../../../utils";

const { RangePicker } = DatePicker;

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ExtraFiles = (
  filedName: string,
  filedPercentage: string,
  rulePermission?: boolean,
) => {
  const location = useLocation();

  return (
    <Col span={12}>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          ["ofLowerValue", "ofHigherValue"].includes(filedName)
            ? prevValues.ruleBidModifier[filedPercentage] !==
                currentValues.ruleBidModifier[filedPercentage] ||
              prevValues.ruleBidModifier.bidPercentage !==
                currentValues.ruleBidModifier.bidPercentage
            : prevValues.ruleBidModifier.bidPercentage !==
              currentValues.ruleBidModifier.bidPercentage
        }
      >
        {({ getFieldValue }) => {
          if (
            getFieldValue(["ruleBidModifier", filedPercentage]) &&
            getFieldValue(["ruleBidModifier", "bidPercentage"])
          ) {
            return (
              <Form.Item
                label="of"
                rules={[{ required: true, message: "this field is required" }]}
                name={["ruleBidModifier", filedName]}
              >
                <Select disabled={!rulePermission} style={{ width: "200px" }}>
                  {location.state.action === "Change campaign budget" ? (
                    <Option value="Current Budget">Current budget</Option>
                  ) : (
                    <>
                      <Option value="EPC">EPC</Option>
                      <Option value="BID">BID</Option>
                      <Option value="ROAS * BID">ROAS * BID</Option>
                    </>
                  )}
                </Select>
              </Form.Item>
            );
          }
          return null;
        }}
      </Form.Item>
    </Col>
  );
};

const GetPrefixSelector = (typeBidPercentage: string, is_budget: boolean) => {
  return (
    <Form.Item
      initialValue={false}
      name={["ruleBidModifier", typeBidPercentage]}
      noStyle
    >
      <Select defaultValue={false} style={{ width: 70 }}>
        <Option value={false}>$</Option>
        {(!is_budget || typeBidPercentage === "bidPercentage") && (
          <Option value={true}>%</Option>
        )}
      </Select>
    </Form.Item>
  );
};

const RuleDetail: React.FC<{ initialValues?: RulesForm }> = ({
  initialValues,
}) => {
  const location = useLocation();
  const params = useParams();

  const [conditionState, setConditionState] = useState(false);
  const [loading, setLoading] = useState(false);

  const [specificRangeChecked, setSpecificRangeChecked] = useState(false);
  const [includeTodayChecked, setIncludeTodayChecked] = useState(false);
  const [createRule, { status: createStatus }] =
    rulesApi.useCreateRuleMutation();
  const [updateRule, { status: updateStatus }] =
    rulesApi.useUpdateRuleMutation();
  const { data: user } = campaignsApi.useGetUserQuery();

  const [userState, setUserState] = useState<GetCurrentUserInterface>();
  const [userPermission, setUserPermission] = useState<boolean>();
  const budget = location.state.action === "Change campaign budget";
  const campaign_bid = location.state.action === "Change campaign bid";

  useEffect(() => {
    setUserState(user);
  }, [user]);

  useEffect(() => {
    setUserPermission(
      !!userState?.role.permission?.find(
        (item) => item.name === RULE_PERMISSION,
      )?.name,
    );
  }, [userState]);
  const addRef = useRef<HTMLButtonElement>(null);
  const source_type = [
    "Change section bid modifier",
    "Block section",
    "Change campaign bid cap",
  ].includes(location.state.action)
    ? "outbrain"
    : [
        "Block publisher",
        "Pause ad",
        "Change campaign bid",
        "Change campaign budget",
      ].includes(location.state.action)
    ? undefined
    : "taboola";
  const { data, status: campaignLoading } =
    campaignsApi.useFetchAllCampaignsQuery({
      filterValue: "",
      filterSourceValue: location.state.action ? source_type : undefined,
      isOptimizationType:
        location.state.action &&
        location.state.action == "Change section bid modifier",
      isDailyBudget:
        location.state.action &&
        location.state.action == "Change campaign budget",
    });
  const navigate = useNavigate();
  const [selectedRange, setSelectedRange] = useState<string[]>([]);
  const [form] = Form.useForm();

  const [selected, setSelected] = useState<Array<any>>([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<
    Array<number> | string
  >([]);
  const [isAllActiveChecked, setIsAllActiveChecked] = useState<boolean>(
    initialValues?.isActiveCampaigns ?? false,
  );

  useEffect(() => {
    if (initialValues && data) {
      const campaignArray: Array<{ label?: string; value: number }> = [];
      const campaigns_ids = initialValues.campaigns;
      if (Array.isArray(campaigns_ids)) {
        campaigns_ids?.map((campaign) => {
          campaignArray.push({
            label: data.find((campaignData) => campaignData.id == campaign)
              ?.name,
            value: campaign,
          });
        });
      }
      setSelected(campaignArray);
      const selectedValues = campaignArray.map((campaign) => campaign.value);
      selectedValues && setSelectedCampaigns(selectedValues);
    }
  }, [initialValues, data]);

  const changeSpecificRangeChecked = () => {
    setSpecificRangeChecked(!specificRangeChecked);
  };

  const changeIncludeTodayChecked = () => {
    setIncludeTodayChecked(!includeTodayChecked);
  };

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Success",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Error",
    });
  };

  const errorCampaigns = () => {
    messageApi.open({
      type: "error",
      content: "Select campaign",
    });
  };
  const onFinish = async (values: RulesForm) => {
    setLoading(true);
    if (initialValues) {
      if (
        (Array.isArray(values.campaigns) && values.campaigns.length > 0) ||
        (Array.isArray(selectedCampaigns) && selectedCampaigns.length > 0) ||
        isAllActiveChecked
      ) {
        const data = {
          rule: { name: values.name },
          dateInterval: {
            interval: specificRangeChecked ? "" : values.interval,
            includeToday: specificRangeChecked
              ? false
              : values.includeToday ?? false,
            specificInterval:
              specificRangeChecked && selectedRange[0] && selectedRange[1]
                ? {
                    dateFrom: selectedRange[0],
                    dateTo: selectedRange[1],
                  }
                : specificRangeChecked
                ? initialValues.specificInterval
                : null,
          },
          schedule: { schedule: values.schedule },
          conditions: values?.conditions,
          campaigns:
            typeof values.campaigns == "string"
              ? selectedCampaigns
              : values.campaigns,
          publisherBidModifier: values.ruleBidModifier?.bid
            ? {
                ofHigherValue: values.ruleBidModifier?.ofHigherValue,
                ofLowerValue: values.ruleBidModifier?.ofLowerValue,
                lowerBidPercentage:
                  values.ruleBidModifier?.lowerBidPercentage ?? false,
                higherBidPercentage:
                  values.ruleBidModifier?.higherBidPercentage ?? false,
                bid: values.ruleBidModifier?.bid,
                bidPercentage: values.ruleBidModifier?.bidPercentage,
                bidHigherThan:
                  values.ruleBidModifier?.bidHigherThan &&
                  values.ruleBidModifier?.bidHigherThan !== ""
                    ? values.ruleBidModifier?.bidHigherThan
                    : null,
                bidLowerThan:
                  values.ruleBidModifier?.bidLowerThan &&
                  values.ruleBidModifier?.bidLowerThan !== ""
                    ? values.ruleBidModifier?.bidLowerThan
                    : null,
                ofValue: values.ruleBidModifier?.ofValue,
              }
            : null,
          id: Number(params.id),
          rulePriority: Number(values.rulePriority) ?? 1,
          isActiveCampaigns: values.isActiveCampaigns,
        };
        await updateRule(data);
      } else {
        errorCampaigns();
        setLoading(false);
      }
    } else {
      if (
        (Array.isArray(values.campaigns) && values.campaigns.length > 0) ||
        (Array.isArray(selectedCampaigns) && selectedCampaigns.length > 0) ||
        isAllActiveChecked
      ) {
        const data = {
          rule: { name: values.name },
          dateInterval: {
            interval: specificRangeChecked ? "" : values.interval,
            includeToday: specificRangeChecked
              ? false
              : values.includeToday ?? false,
            specificInterval:
              selectedRange[0] && selectedRange[1]
                ? {
                    dateFrom: selectedRange[0],
                    dateTo: selectedRange[1],
                  }
                : null,
          },
          action: { name: location.state.action },
          schedule: { schedule: values.schedule },
          conditions: values?.conditions,
          campaigns:
            typeof values.campaigns == "string"
              ? selectedCampaigns
              : values.campaigns,
          publisherBidModifier: values.ruleBidModifier?.bid
            ? {
                ofHigherValue: values.ruleBidModifier?.ofHigherValue,
                ofLowerValue: values.ruleBidModifier?.ofLowerValue,
                lowerBidPercentage:
                  values.ruleBidModifier?.lowerBidPercentage ?? false,
                higherBidPercentage:
                  values.ruleBidModifier?.higherBidPercentage ?? false,
                bid: values.ruleBidModifier?.bid,
                bidPercentage: values.ruleBidModifier?.bidPercentage,
                bidHigherThan:
                  values.ruleBidModifier?.bidHigherThan &&
                  values.ruleBidModifier?.bidHigherThan !== ""
                    ? values.ruleBidModifier?.bidHigherThan
                    : null,
                bidLowerThan:
                  values.ruleBidModifier?.bidLowerThan &&
                  values.ruleBidModifier?.bidLowerThan !== ""
                    ? values.ruleBidModifier?.bidLowerThan
                    : null,
                ofValue: values.ruleBidModifier?.ofValue,
              }
            : null,
          rulePriority: Number(values.rulePriority) ?? 1,
          isActiveCampaigns: values.isActiveCampaigns,
        };

        await createRule(data);
      } else {
        errorCampaigns();
        setLoading(false);
      }
    }
  };
  if (createStatus === "fulfilled" || updateStatus === "fulfilled") {
    success();
    setTimeout(() => {
      navigate("/rules");
    }, 800);
  }
  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (createStatus === "rejected" || updateStatus === "rejected") {
      error();
      setLoading(false);
    }
  }, [createStatus, updateStatus]);

  useEffect(() => {
    if (initialValues?.specificInterval) {
      setSpecificRangeChecked(true);
    }
    if (initialValues?.includeToday) {
      setIncludeTodayChecked(true);
    }
  }, [initialValues]);

  useEffect(() => {
    if (!conditionState && addRef && !initialValues) {
      addRef.current?.click();
      setConditionState(true);
    }
  }, [conditionState, initialValues]);

  const options = data?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const handleSelectedCampaign = (
    campaigns: Array<{ label: string; value: number }> | string,
  ) => {
    if (typeof campaigns !== "string") {
      setSelected(campaigns);
      const selectedValues = campaigns.map((campaign) => campaign.value);
      setSelectedCampaigns(selectedValues);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ campaigns: selectedCampaigns });
  }, [selectedCampaigns, form]);

  const bidBudget = budget ? "Budget" : "Bid";

  const handleAllActiveCampaigns = (value: React.MouseEvent<HTMLElement>) => {
    const checked = (value.target as HTMLInputElement)?.checked;
    setIsAllActiveChecked(checked);
  };

  return campaignLoading == "fulfilled" ? (
    <>
      <Form
        layout="vertical"
        form={form}
        {...layout}
        name="control-ref"
        onFinish={onFinish}
        style={{ maxWidth: 1200, marginBottom: 100 }}
        initialValues={initialValues}
      >
        {contextHolder}
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "this field is required" }]}
        >
          <Input disabled={!userPermission} />
        </Form.Item>
        <Form.Item
          name="interval"
          label="Considering data from"
          rules={[
            {
              required: !specificRangeChecked,
              message: "this field is required",
            },
          ]}
        >
          <Select
            disabled={!userPermission || specificRangeChecked}
            placeholder="Select a option"
          >
            <Option value="Today">Today</Option>
            <Option value="Yesterday">Yesterday</Option>
            <Option value="Today & Yesterday">Today & Yesterday</Option>
            <Option value="Last 3 Days">Last 3 Days</Option>
            <Option value="Last 4 Days">Last 4 Days</Option>
            <Option value="Last 5 Days">Last 5 Days</Option>
            <Option value="Last 6 Days">Last 6 Days</Option>
            <Option value="Last 7 Days">Last 7 Days</Option>
            <Option value="Last 8 Days">Last 8 Days</Option>
            <Option value="Last 9 Days">Last 9 Days</Option>
            <Option value="Last 10 Days">Last 10 Days</Option>
            <Option value="Last 11 Days">Last 11 Days</Option>
            <Option value="Last 12 Days">Last 12 Days</Option>
            <Option value="Last 13 Days">Last 13 Days</Option>
            <Option value="Last 14 Days">Last 14 Days</Option>
            <Option value="Last 30 Days">Last 30 Days</Option>
            <Option value="Last 60 Days">Last 60 Days</Option>
            <Option value="Last 90 Days">Last 90 Days</Option>
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "this field is required" }]}
          name="rulePriority"
          label="Rule priority"
        >
          <Select
            showSearch
            style={{ width: 120 }}
            options={[
              { value: "1", label: "1" },
              { value: "2", label: "2" },
              { value: "3", label: "3" },
              { value: "4", label: "4" },
              { value: "5", label: "5" },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            disabled={!userPermission}
            checked={specificRangeChecked}
            onClick={changeSpecificRangeChecked}
          >
            Specific Date Range
          </Checkbox>
        </Form.Item>
        {specificRangeChecked && (
          <Form.Item
            rules={[{ required: true, message: "this field is required" }]}
            name="specificDateRange"
          >
            {specificRangeChecked && (
              <RangePicker
                disabled={!userPermission}
                format="YYYY-MM-DD"
                onChange={(_, dateString) => setSelectedRange(dateString)}
              />
            )}
          </Form.Item>
        )}
        <Form.Item name="includeToday" valuePropName="checked">
          <Checkbox
            disabled={!userPermission || specificRangeChecked}
            checked={includeTodayChecked}
            onClick={changeIncludeTodayChecked}
          >
            Include today
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Form.List name="conditions">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? "Condition" : ""}
                    required={index === 0}
                    key={field.key}
                  >
                    <b style={{ margin: "0px 10px", fontSize: "18px" }}>if</b>
                    <Form.Item
                      {...field}
                      name={[field.name, "ifValue"]}
                      rules={[
                        { required: true, message: "this field is required" },
                      ]}
                      style={{ display: "inline-block" }}
                      key={`${field.key}-ifValue`}
                    >
                      <Select
                        disabled={!userPermission}
                        style={{ width: "150px" }}
                        placeholder="Select Option"
                      >
                        <Option value="cost">FF Cost</Option>
                        <Option value="spent">spent</Option>
                        <Option value="roas">roas</Option>
                        <Option value="lander_ctr">lander ctr</Option>
                        <Option value="conversions_no_upsell">
                          conversions no upsell
                        </Option>
                        <Option value="conversions_with_upsells">
                          conversions with upsells
                        </Option>
                      </Select>
                    </Form.Item>
                    <b style={{ margin: "0px 10px", fontSize: "18px" }}>is</b>
                    <Form.Item
                      {...field}
                      name={[field.name, "condition"]}
                      rules={[
                        { required: true, message: "this field is required" },
                      ]}
                      style={{
                        display: "inline-block",
                        margin: "0 8px",
                      }}
                      key={`${field.key}-condition`}
                    >
                      <Select
                        disabled={!userPermission}
                        style={{ width: "150px" }}
                        placeholder="Select Option"
                      >
                        <Option value="less">less</Option>
                        <Option value="greater">greater</Option>
                        <Option value="less_or_equal">less or equal</Option>
                        <Option value="greater_or_equal">
                          greater or equal
                        </Option>
                      </Select>
                    </Form.Item>
                    <b style={{ margin: "0px 10px", fontSize: "18px" }}>than</b>
                    <Form.Item
                      {...field}
                      name={[field.name, "value"]}
                      rules={[
                        { required: true, message: "this field is required" },
                      ]}
                      style={{
                        display: "inline-block",
                      }}
                      key={`${field.key}-value`}
                    >
                      <InputNumber
                        disabled={!userPermission}
                        name="content"
                        style={{ width: "100px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, "valueType"]}
                      rules={[
                        { required: true, message: "this field is required" },
                      ]}
                      style={{
                        display: "inline-block",
                        margin: "0px 25px",
                      }}
                      initialValue={budget ? "current_budget" : "decimal"}
                    >
                      <Select
                        disabled={!userPermission}
                        defaultValue={budget ? "current_budget" : "decimal"}
                        style={{ width: 150 }}
                      >
                        <Option value={"decimal"}>$</Option>
                        <Option value={"percentage"}>%</Option>
                        {budget ? (
                          <Option value={"current_budget"}>
                            current budget
                          </Option>
                        ) : !campaign_bid ? (
                          <Option value={"control_lander_ctr"}>
                            control lander ctr
                          </Option>
                        ) : null}
                      </Select>
                    </Form.Item>
                    {index > 0 && (
                      <Button
                        type="link"
                        disabled={!userPermission}
                        onClick={() => remove(field.name)}
                      >
                        Remove
                      </Button>
                    )}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    disabled={!userPermission}
                    ref={addRef}
                    type="dashed"
                    onClick={() => add()}
                    block
                  >
                    + Add Condition
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        {(location.state.action === "Change publisher bid modifier" ||
          location.state.action === "Change section bid modifier" ||
          location.state.action === "Change campaign bid" ||
          location.state.action === "Change campaign bid cap" ||
          location.state.action === "Change campaign budget") && (
          <Form.Item name="ruleBidModifier" label="Rule">
            <Row>
              <Col span={12}>
                <Form.Item
                  rules={[
                    { required: true, message: "this field is required" },
                  ]}
                  style={{ width: "300px", display: "inline-block" }}
                  name={["ruleBidModifier", "bid"]}
                  label={
                    location.state.action === "Change campaign budget"
                      ? "Budget"
                      : "Bid"
                  }
                >
                  <Input
                    disabled={!userPermission}
                    addonAfter={GetPrefixSelector("bidPercentage", budget)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                {ExtraFiles("ofValue", "bidPercentage", userPermission)}
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.ruleBidModifier.bidPercentage !==
                    currentValues.ruleBidModifier.bidPercentage
                  }
                >
                  {({ getFieldValue }) => {
                    if (getFieldValue(["ruleBidModifier", "bidPercentage"])) {
                      return (
                        <Form.Item
                          style={{
                            width: "300px",
                          }}
                          name={["ruleBidModifier", "bidLowerThan"]}
                          label={
                            "Do not allow the " +
                            bidBudget +
                            " to be lower than"
                          }
                        >
                          <Input
                            disabled={!userPermission}
                            addonAfter={GetPrefixSelector(
                              "lowerBidPercentage",
                              budget,
                            )}
                          />
                        </Form.Item>
                      );
                    }
                    return null;
                  }}
                </Form.Item>
              </Col>
              <Col span={12}>
                {ExtraFiles(
                  "ofLowerValue",
                  "lowerBidPercentage",
                  userPermission,
                )}
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.ruleBidModifier.bidPercentage !==
                    currentValues.ruleBidModifier.bidPercentage
                  }
                >
                  {({ getFieldValue }) => {
                    if (getFieldValue(["ruleBidModifier", "bidPercentage"])) {
                      return (
                        <Form.Item
                          style={{
                            width: "300px",
                          }}
                          name={["ruleBidModifier", "bidHigherThan"]}
                          label={
                            "Do not allow the " +
                            bidBudget +
                            " to be higher than"
                          }
                        >
                          <Input
                            disabled={!userPermission}
                            addonAfter={GetPrefixSelector(
                              "higherBidPercentage",
                              budget,
                            )}
                          />
                        </Form.Item>
                      );
                    }
                    return null;
                  }}
                </Form.Item>
              </Col>
              <Col span={12}>
                {ExtraFiles(
                  "ofHigherValue",
                  "higherBidPercentage",
                  userPermission,
                )}
              </Col>
            </Row>
          </Form.Item>
        )}
        <Form.Item
          name="isActiveCampaigns"
          id="isActiveCampaigns"
          valuePropName="checked"
        >
          <Checkbox
            disabled={!userPermission}
            onClick={(value) => handleAllActiveCampaigns(value)}
          >
            Select all active campaigns
          </Checkbox>
        </Form.Item>
        {options && (
          <Form.Item
            rules={[
              {
                required: !isAllActiveChecked,
                message: "this field is required",
              },
            ]}
            name="campaigns"
            id="campaigns"
            label="Apply Rule to Campaigns"
          >
            <div>
              <MultiSelect
                disabled={!userPermission || isAllActiveChecked}
                options={options}
                value={selected}
                onChange={handleSelectedCampaign}
                labelledBy="Select"
                ClearSelectedIcon={<CloseOutlined style={{ color: "grey" }} />}
              />
            </div>
          </Form.Item>
        )}
        <Form.Item
          rules={[{ required: true, message: "this field is required" }]}
          name="schedule"
          label="Run this rule every"
        >
          <Select placeholder="Select Option" disabled={!userPermission}>
            <Option value="Every 5 minutes">Every 5 minutes</Option>
            <Option value="Every 10 minutes">Every 10 minutes</Option>
            <Option value="Every 20 minutes">Every 20 minutes</Option>
            <Option value="Every 30 minutes">Every 30 minutes</Option>
            <Option value="Every hour">Every hour</Option>
            <Option value="Every 2 hours">Every 2 hours</Option>
            <Option value="Every 3 hours">Every 3 hours</Option>
            <Option value="Every 4 hours">Every 4 hours</Option>
            <Option value="Every 6 hours">Every 6 hours</Option>
            <Option value="Every 12 hours">Every 12 hours</Option>
            <Option value="Every day">Every day</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Space>
            <Button
              disabled={!userPermission}
              htmlType="button"
              onClick={onReset}
            >
              Reset
            </Button>
            <Button
              disabled={!userPermission}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  ) : (
    <div></div>
  );
};

export default RuleDetail;
