import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "../../../utils/axiosBaseQuery";
import {
  IRuleChangeStatus,
  LogsFilterInterface,
  RuleCreate,
  RuleItem,
  RuleLogsItem,
  RuleUpdate,
} from "../../../components/rules/types";

export const rulesApi = createApi({
  reducerPath: "rulesApi",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_HOST || "http://localhost:8000",
  }),

  endpoints: (build) => ({
    fetchAllRules: build.query<RuleItem[], any>({
      query: () => ({
        url: "/rule",
        method: "get",
      }),
    }),
    fetchRulesByAction: build.query<RuleLogsItem[], LogsFilterInterface>({
      query: (data) => ({
        url: `/rule/get/logs`,
        method: "get",
        params: {
          action: data.action,
          status: data.status,
          date_from: data.date_from,
          date_to: data.date_to,
          rule_id: undefined,
          limit: undefined,
        },
      }),
    }),
    fetchRulesByActionByRuleId: build.query<
      RuleLogsItem[],
      LogsFilterInterface
    >({
      query: (data) => ({
        url: `/rule/get/logs`,
        method: "get",
        params: {
          action: data.action,
          status: data.status,
          date_from: data.date_from,
          date_to: data.date_to,
          rule_id: data.rule_id,
          limit: data.limit,
        },
      }),
    }),
    fetchRuleDetail: build.query<RuleItem, number>({
      query: (ruleId) => ({
        url: `/rule/${ruleId}`,
        method: "get",
      }),
    }),
    deleteRule: build.mutation<any, number>({
      query: (id) => ({
        url: `rule/${id}`,
        method: "delete",
        credentials: "include",
      }),
    }),
    executeNowRule: build.query<any, number>({
      query: (data) => ({
        url: `/rule/execute_rule/${data}`,
        method: "get",
        data: data,
      }),
    }),
    duplicateRule: build.query<any, number>({
      query: (data) => ({
        url: `/rule/duplicate_rule/${data}`,
        method: "post",
        data: data,
      }),
    }),
    createRule: build.mutation<RuleItem, RuleCreate>({
      query: (data) => ({
        url: "/rule/create",
        method: "post",
        data: data,
      }),
    }),
    updateRuleStatus: build.mutation<any, number>({
      query: (ruleId) => ({
        url: `/rule/update_rule_status?rule_id=${ruleId}`,
        method: "patch",
      }),
    }),
    updateRule: build.mutation<any, RuleUpdate>({
      query: (data) => ({
        url: `/rule/${data.id}/update`,
        method: "put",
        data: data,
      }),
    }),
    changeRuleStatus: build.mutation<any, IRuleChangeStatus>({
      query: (data) => ({
        url: `/rule/change_status`,
        method: "put",
        data: data,
      }),
    }),
  }),
});
