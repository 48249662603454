import React, { useEffect, useState } from "react";
import { Select, Tabs } from "antd";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { campaignDetailApi } from "../../../../redux/services/campaignDetailApi";
import { getTodayFormatted } from "../../../../utils";
import FilterInput from "../filterInput";
import {
  ColumnNameEnum,
  CreatePresetInterface,
  GetPresetInterface,
  RequestFilterInterface,
  SectionTableInterface,
} from "../../types";
import DatePickerComponent from "../datePicker";
import SectionTable from "./sectionTable";
import { campaignsApi } from "../../../../redux/services/campaignsApi";
import { columnsSectionType } from "./columnType";
import { ColumnType } from "antd/es/list";
import CreatePreset from "../../../presets";

const { TabPane } = Tabs;

const Sections = () => {
  const [date, setDate] = useState(String(getTodayFormatted()));

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [fetchSections, { data, status }] =
    campaignDetailApi.useLazyFetchCampaignSectionsQuery();
  const [dateTo, setDateTo] = useState(String(getTodayFormatted()));
  const [rangePickerDate, setRangePickerDate] = useState();
  const [filterValue, setFilterValue] = useState<string>("");
  const [sectionTable, setSectionTable] = useState<SectionTableInterface[]>();
  const [presets, setPresets] = useState<GetPresetInterface[]>([]);
  const [getPresets, { data: presetsData, status: getPresetStatus }] =
    campaignsApi.useLazyGetPresetsQuery();
  const [getCurrentUser, { data: CurrentUser }] =
    campaignsApi.useLazyGetCurrentUserQuery();
  const [searchValue, setSearchValue] = useState<string>();
  const [currentPreset, setCurrentPreset] = useState<CreatePresetInterface>();

  useEffect(() => {
    fetchSections({
      id: params?.id || "",
      date: date,
      date_to: dateTo,
      filterValue: filterValue,
    })
      .unwrap()
      .catch((err) => {
        if (err && err.status === 404) {
          navigate("/campaigns");
        }
      });
    getPresets("SectionTable");
    getCurrentUser(undefined);
  }, [date, dateTo]);

  const onChangeSelectedPreset = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    setCurrentPreset(presets.find((item) => item.name == searchValue));
  }, [searchValue]);

  useEffect(() => {
    if (!presetsData) return;
    setPresets(presetsData);
  }, [presetsData]);

  useEffect(() => {
    if (data) {
      const sectionsData = data?.map((section) => ({
        id: section.id,
        campaignId: section.campaignId,
        sectionId: section.sectionId,
        name: section.section?.name,
        externalId: section.section?.externalId,
        url: section.section?.url,
        publisherId: section.section?.publisherId,
        impressions: section.impressions ?? 0,
        clicks: section.clicks ?? 0,
        spent: section.spent ?? 0,
        cpc: section.cpc ?? 0,
        ctr: section.ctr ?? 0,
        cpa: section.cpa ?? 0,
        roas: section.roas ?? 0,
        totalRoas: section.totalRoas ?? 0,
        totalConversions: section.totalConversions ?? 0,
        conversions: section.conversions ?? 0,
        viewConversions: section.viewConversions ?? 0,
        conversionRate: section.conversionRate ?? 0,
        viewConversionRate: section.viewConversionRate ?? 0,
        totalCpa: section.totalCpa ?? 0,
        totalSumValue: section.totalSumValue ?? 0,
        sumValue: section.sumValue ?? 0,
        viewSumValue: section.viewSumValue ?? 0,
        totalAverageValue: section.totalAverageValue ?? 0,
        averageValue: section.averageValue ?? 0,
        viewAverageValue: section.viewAverageValue ?? 0,
        conversionsWithUpsells: section.conversionsWithUpsells ?? 0,
        conversionsNoUpsell: section.conversionsNoUpsell ?? 0,
        revenueWithUpsell: section.revenueWithUpsell ?? 0,
        revenueNoUpsells: section.revenueNoUpsells ?? 0,
        landerViews: section.landerViews ?? 0,
        landerClicks: section.landerClicks ?? 0,
        landerCtr: section.landerCtr ?? 0,
        updatedAt: section.updatedAt ?? "",
        date: section.date,
      }));
      setSectionTable(sectionsData);
    }
  }, [data]);

  const column = columnsSectionType;

  const handleTabChange = (key: string) => {
    if (key === "1") {
      navigate(-1);
    } else if (key === "2") {
      navigate(`/campaigns/${params.id}/publishers`);
    }
  };

  const onChangeDateFrom = (date: string) => {
    setDate(date);
  };

  const onChangeDateTo = (date: string) => {
    setDateTo(date);
  };

  const onChangeRangePickerDate = (arg: any) => {
    setRangePickerDate(arg);
  };

  const onChangeFilterInput = (obj: RequestFilterInterface) => {
    fetchSections({
      id: obj.id,
      date: obj.date,
      date_to: obj.date_to,
      filterValue: obj.filterValue,
    });
    setFilterValue(obj.filterValue ?? "");
  };

  const [columnList, setColumnList] = useState<
    Array<{ label: string; value: string }>
  >(() => {
    return column.map((item) => {
      const spanElement = React.Children.only(item.title) as React.ReactElement;
      const label = spanElement.props.children;
      const key = typeof item.key === "string" ? item.key : String(item.key);
      return {
        label: label,
        value: key,
      };
    });
  });

  useEffect(() => {
    if (presets[0]) {
      setCurrentPreset(presets[0]);
    } else {
      setCurrentPreset(undefined);
    }
  }, [presets]);

  useEffect(() => {
    if (!column) {
      return;
    }

    if (presets.length === 0) {
      setColumnState(column);
      return;
    }

    const columnSettingsArray = JSON.parse(
      currentPreset?.column_settings || "[]",
    );
    const columnKeyMap: Record<string, any> = {};
    column.forEach((col) => {
      const key = String(col.key ?? "");
      columnKeyMap[key] = key;
    });

    const newColumnOrder = columnSettingsArray
      .map((setting: { value: string }) => columnKeyMap[setting.value])
      .filter(
        (col: ColumnType | undefined | null) =>
          col !== undefined && col !== null,
      );

    const filteredColumns = newColumnOrder.map((columnName: string) => {
      return column.find((col) => col.key === columnName);
    });

    setColumnState(filteredColumns);
  }, [currentPreset, presets]);

  const [columnState, setColumnState] = useState(column);

  const handleRefetchPresets = () => {
    getPresets("SectionTable");
  };

  return (
    <div style={{ position: "relative" }}>
      <Tabs
        animated={false}
        activeKey={location.pathname.includes("sections") ? "3" : "1"}
        onChange={handleTabChange}
        style={{
          marginTop: "50px",
        }}
      >
        <TabPane tab="Ads" key="1"></TabPane>
        <TabPane tab="Publishers" key="2"></TabPane>
        <TabPane tab="Sections" key="3">
          <SectionTable
            loading={getPresetStatus !== "fulfilled"}
            dataSource={sectionTable ? sectionTable : []}
            column={columnState}
          />
        </TabPane>
      </Tabs>
      <div style={{ position: "absolute", top: -35, right: 16 }}>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "5px", minWidth: "270px" }}>
            <DatePickerComponent
              onChangeDateFrom={onChangeDateFrom}
              onChangeDateTo={onChangeDateTo}
              onChangeRangePicker={onChangeRangePickerDate}
              setRangePickerDate={setRangePickerDate}
              rangePickerDate={rangePickerDate}
              dateFrom={date}
              dateTo={dateTo}
            />
          </div>
          <FilterInput
            onFilterChange={onChangeFilterInput}
            campaignId={params?.id}
            date={date}
            dateTo={dateTo}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div></div>
          <div style={{ display: "flex" }}>
            {CurrentUser && CurrentUser.role.name == "admin" && (
              <div style={{ marginRight: "5px" }}>
                <CreatePreset
                  tableName={"SectionTable" as ColumnNameEnum}
                  columns={columnList}
                  presets={presets}
                  trigger={handleRefetchPresets}
                />
              </div>
            )}
            <Select
              showSearch
              placeholder="Select a preset"
              style={{ minWidth: "145px", width: "145px" }}
              onChange={onChangeSelectedPreset}
              value={currentPreset?.name}
              options={presets.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sections;
