import { useDispatch, useSelector } from "react-redux";
import { setAccessToken } from "../redux/reducers/auth";
import RootState from "../redux/store";
import { useEffect } from "react";

const useAuth = (initialAccessToken: string | null = null) => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);

  useEffect(() => {
    if (initialAccessToken) {
      dispatch(setAccessToken("bearer " + initialAccessToken));
    }
  }, [initialAccessToken]);

  return accessToken;
};

export default useAuth;
