import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetCurrentUserInterface } from "../components/campaigns/types";

function usePermissionRedirect(
  user: GetCurrentUserInterface | undefined,
  permission: string,
) {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      user &&
      !user?.role?.permission?.some(
        (userPermission) => userPermission.name === permission,
      )
    ) {
      navigate(-1);
    }
  }, [user, permission, navigate]);
}

export default usePermissionRedirect;
