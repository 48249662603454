import { Form, Input, Button } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthResponse } from "./types";
import { setAccessToken } from "../../redux/reducers/auth";
import { useDispatch } from "react-redux";

const LoginPage = () => {
  const host = process.env.REACT_APP_HOST;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (values: any) => {
    try {
      const formData = new FormData();
      formData.append("username", values.email);
      formData.append("password", values.password);
      const response = await axios.post<AuthResponse>(
        `${host}/auth/login`,
        formData,
      );
      const access_token =
        response.data.token_type + " " + response.data.access_token;
      const refresh_token =
        response.data.token_type + " " + response.data.refresh_token;

      localStorage.setItem("accessToken", access_token);
      localStorage.setItem("refreshToken", refresh_token);
      dispatch(setAccessToken(access_token));

      navigate("/");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        marginTop: "-5%",
      }}
    >
      <div>
        <h1 style={{ display: "flex", justifyContent: "center" }}>Login</h1>
        <Form onFinish={onFinish}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter your email" },
              { type: "email", message: "Invalid email format" },
            ]}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 18 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please enter your password" }]}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 18 }}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
