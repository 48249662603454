import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "../../../utils/axiosBaseQuery";
import {ICreateSource, IDataSource, IDataSourceById, IUpdateSource} from "../../../components/account/types";

export const sourceApi = createApi({
  reducerPath: "sourceApi",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_HOST || "http://localhost:8000",
  }),

  endpoints: (build) => ({
    fetchSourceById: build.query<IDataSourceById, number>({
      query: (source_id) => ({
        url: `/sources/${source_id}`,
        method: "get",
      }),
    }),
    fetchSources: build.query<IDataSource[], any>({
      query: () => ({
        url: "/sources/",
        method: "get",
      }),
    }),

    createSource: build.mutation<ICreateSource, ICreateSource>({
      query: (data) => ({
        url: "/sources/create",
        method: "post",
        data: data,
      }),
    }),
    updateSource: build.mutation<IUpdateSource, IUpdateSource>({
      query: (data) => ({
        url: `/sources/update`,
        method: "put",
        data: data
      }),
    }),
  }),
});
