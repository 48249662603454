import { Button, message, Space } from "antd";
import SourceTable from "./sourceTable";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/Interceptor";
import { useEffect, useState } from "react";
import { sourceApi } from "../../redux/services/sourceApi";
import { campaignsApi } from "../../redux/services/campaignsApi";
import { CREATE_PERMISSION, UPDATE_PERMISSION } from "../../utils";

export const Source = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { data, status, refetch } = sourceApi.useFetchSourcesQuery("");
  const [messageApi, contextHolder] = message.useMessage();

  const { data: user } = campaignsApi.useGetUserQuery();

  const handleFetchAccounts = async () => {
    setLoading(true);
    await axiosInstance
      .get("/accounts/update")
      .then((result) => {
        if (result.status === 200) {
          refetch();
        }
      })
      .catch(() => {
        error();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Error",
    });
  };

  useEffect(() => {
    refetch();
  }, []);

  const [isAllowToUpdate, setIsAllowToUpdate] = useState<boolean>(
    !!user?.role.permission?.find(
      (permission) => permission.name === UPDATE_PERMISSION,
    )?.name,
  );

  const [isAllowToCreate, setIsAllowToCreate] = useState<boolean>(
    !!user?.role.permission?.find(
      (permission) => permission.name === CREATE_PERMISSION,
    )?.name,
  );

  useEffect(() => {
    setIsAllowToCreate(
      !!user?.role.permission?.find(
        (permission) => permission.name === CREATE_PERMISSION,
      )?.name,
    );
    setIsAllowToUpdate(
      !!user?.role.permission?.find(
        (permission) => permission.name === UPDATE_PERMISSION,
      )?.name,
    );
  }, [user]);

  return (
    <div>
      {contextHolder}
      <Space size="middle" style={{ marginBottom: "1%" }}>
        <Button
          disabled={!isAllowToCreate}
          onClick={() => navigate("/source/create")}
          icon={<PlusOutlined />}
        >
          Create Source
        </Button>
        <Button
          disabled={!isAllowToCreate}
          loading={loading}
          onClick={() => handleFetchAccounts()}
        >
          Fetch Accounts
        </Button>
      </Space>
      <SourceTable
        data={data ? data : []}
        status={status}
        refetch={refetch}
        isAllowToUpdate={isAllowToUpdate}
      />
    </div>
  );
};
