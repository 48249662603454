import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitialState {
  selectedRule: number[];
}

const initialState: IInitialState = {
  selectedRule: [],
};

export const rulesSlice = createSlice({
  name: "rules",
  initialState,
  reducers: {
    addSelectedRule(state, action: PayloadAction<number>) {
      state.selectedRule.push(action.payload);
    },
    addSelectedRulesList(state, action: PayloadAction<number[]>) {
      state.selectedRule.push(...action.payload);
    },
    removeSelectedRule(state, action: PayloadAction<number>) {
      state.selectedRule = state.selectedRule.filter(
        (item) => item !== action.payload
      );
    },
    clearSelectedRules(state) {
      state.selectedRule = [];
    },
  },
});
export const {
  addSelectedRule,
  clearSelectedRules,
  removeSelectedRule,
  addSelectedRulesList,
} = rulesSlice.actions;
