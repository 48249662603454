import { ColumnsType } from "antd/es/table";
import { IPublisher } from "../../types";
import React from "react";

export const columnsPublisherType: ColumnsType<IPublisher> = [
  {
    title: <span className="dragHandler">Blocking Level</span>,
    dataIndex: "blockingLevel",
    key: "blockingLevel",
    width: 100,
  },
  {
    title: <span className="dragHandler">Site ID</span>,
    dataIndex: "siteId",
    key: "siteId",
    width: 100,
  },
  {
    title: <span className="dragHandler">Site</span>,
    dataIndex: "site",
    key: "site",
    width: 100,
  },
  {
    title: <span className="dragHandler">Site Name</span>,
    dataIndex: "siteName",
    key: "siteName",
    width: 100,
  },
  {
    title: <span className="dragHandler">Clicks</span>,
    dataIndex: "clicks",
    key: "clicks",
    sorter: (a, b) => (a.clicks || 0) - (b.clicks || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Impressions</span>,
    dataIndex: "impressions",
    key: "impressions",
    sorter: (a, b) => (a.impressions || 0) - (b.impressions || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Visible Impressions</span>,
    dataIndex: "visibleImpressions",
    key: "visibleImpressions",
    sorter: (a, b) => (a.visibleImpressions || 0) - (b.visibleImpressions || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Spent</span>,
    dataIndex: "spent",
    key: "spent",
    sorter: (a, b) => (a.spent || 0) - (b.spent || 0),
    render: (_, { spent }) => <>{spent ? "£" + spent.toFixed(4) : null}</>,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Conversions Value</span>,
    dataIndex: "conversionsValue",
    key: "conversionsValue",
    sorter: (a, b) => (a.conversionsValue || 0) - (b.conversionsValue || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">ROAS</span>,
    dataIndex: "roas",
    key: "roas",
    sorter: (a, b) => (a.roas || 0) - (b.roas || 0),
    render: (_, { roas }) => (
      <>{roas !== null ? (roas == 0 ? 0 : roas.toFixed(4) + "%") : null}</>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CTR</span>,
    dataIndex: "ctr",
    key: "ctr",
    sorter: (a, b) => (a.ctr || 0) - (b.ctr || 0),
    render: (_, { ctr }) => (
      <>{ctr !== null ? (ctr == 0 ? 0 : ctr.toFixed(4) + "%") : null}</>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">VCTR</span>,
    dataIndex: "vctr",
    key: "vctr",
    sorter: (a, b) => (a.vctr || 0) - (b.vctr || 0),
    render: (_, { vctr }) => (
      <>{vctr !== null ? (vctr == 0 ? 0 : vctr.toFixed(4) + "%") : null}</>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPM</span>,
    dataIndex: "cpm",
    key: "cpm",
    sorter: (a, b) => (a.cpm || 0) - (b.cpm || 0),
    render: (_, { cpm }) => (
      <>{cpm !== null ? (cpm == 0 ? 0 : "£" + cpm.toFixed(4)) : null}</>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">VCPM</span>,
    dataIndex: "vcpm",
    key: "vcpm",
    sorter: (a, b) => (a.vcpm || 0) - (b.vcpm || 0),
    render: (_, { vcpm }) => (
      <>{vcpm !== null ? (vcpm == 0 ? 0 : "£" + vcpm.toFixed(4)) : null}</>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPC</span>,
    dataIndex: "cpc",
    key: "cpc",
    sorter: (a, b) => (a.cpc || 0) - (b.cpc || 0),
    render: (_, { cpc }) => (
      <>{cpc !== null ? (cpc == 0 ? 0 : "£" + cpc.toFixed(4)) : null}</>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPA</span>,
    dataIndex: "cpa",
    key: "cpa",
    sorter: (a, b) => (a.cpa || 0) - (b.cpa || 0),
    render: (_, { cpa }) => (
      <>{cpa !== null ? (cpa == 0 ? 0 : "£" + cpa.toFixed(4)) : ""}</>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPA Clicks</span>,
    dataIndex: "cpaClicks",
    key: "cpaClicks",
    sorter: (a, b) => (a.cpaClicks || 0) - (b.cpaClicks || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPA Views</span>,
    dataIndex: "cpaViews",
    key: "cpaViews",
    sorter: (a, b) => (a.cpaViews || 0) - (b.cpaViews || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPA Actions Num</span>,
    dataIndex: "cpaActionsNum",
    key: "cpaActionsNum",
    sorter: (a, b) => (a.cpaActionsNum || 0) - (b.cpaActionsNum || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPA Actions Num from Clicks"</span>,
    dataIndex: "cpaActionsNumFromClicks",
    key: "cpaActionsNumFromClicks",
    sorter: (a, b) =>
      (a.cpaActionsNumFromClicks || 0) - (b.cpaActionsNumFromClicks || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPA Actions Num from Views</span>,
    dataIndex: "cpaActionsNumFromViews",
    key: "cpaActionsNumFromViews",
    sorter: (a, b) =>
      (a.cpaActionsNumFromViews || 0) - (b.cpaActionsNumFromViews || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPA Conversion Rate</span>,
    dataIndex: "cpaConversionRate",
    key: "cpaConversionRate",
    sorter: (a, b) => (a.cpaConversionRate || 0) - (b.cpaConversionRate || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPA Conversion Rate Clicks</span>,
    dataIndex: "cpaConversionRateClicks",
    key: "cpaConversionRateClicks",
    sorter: (a, b) =>
      (a.cpaConversionRateClicks || 0) - (b.cpaConversionRateClicks || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPA Conversion Rate Views</span>,
    dataIndex: "cpaConversionRateViews",
    key: "cpaConversionRateViews",
    sorter: (a, b) =>
      (a.cpaConversionRateViews || 0) - (b.cpaConversionRateViews || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Avg Boost</span>,
    dataIndex: "avgBoost",
    key: "avgBoost",
    sorter: (a, b) => (a.avgBoost || 0) - (b.avgBoost || 0),
    render: (_, { avgBoost }) => (
      <>
        {avgBoost !== null ? (avgBoost == 0 ? 0 : avgBoost?.toFixed(3)) : null}
      </>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Currency</span>,
    dataIndex: "currency",
    key: "currency",
    width: 100,
  },
  {
    title: <span className="dragHandler">Revenue With Upsell</span>,
    dataIndex: "revenueWithUpsell",
    key: "revenueWithUpsell",
    sorter: (a, b) => (a.revenueWithUpsell || 0) - (b.revenueWithUpsell || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">"Conversions With Upsells"</span>,
    dataIndex: "conversionsWithUpsells",
    key: "conversionsWithUpsells",
    sorter: (a, b) =>
      (a.conversionsWithUpsells || 0) - (b.conversionsWithUpsells || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Revenue No Upsells</span>,
    dataIndex: "revenueNoUpsells",
    key: "revenueNoUpsells",
    sorter: (a, b) => (a.revenueNoUpsells || 0) - (b.revenueNoUpsells || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Conversions No Upsell</span>,
    dataIndex: "conversionsNoUpsell",
    key: "conversionsNoUpsell",
    sorter: (a, b) =>
      (a.conversionsNoUpsell || 0) - (b.conversionsNoUpsell || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Cost</span>,
    dataIndex: "cost",
    key: "cost",
    sorter: (a, b) => (a.cost || 0) - (b.cost || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { cost }) => (
      <>{cost !== null ? (cost == 0 ? 0 : cost?.toFixed(3)) : null}</>
    ),
    width: 100,
  },
  {
    title: <span className="dragHandler">Lander Views</span>,
    dataIndex: "landerViews",
    key: "landerViews",
    sorter: (a, b) => (a.landerViews || 0) - (b.landerViews || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Lander Clicks</span>,
    dataIndex: "landerClicks",
    key: "landerClicks",
    sorter: (a, b) => (a.landerClicks || 0) - (b.landerClicks || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Lander CTR</span>,
    dataIndex: "landerCtr",
    key: "landerCtr",
    sorter: (a, b) => (a.landerCtr || 0) - (b.landerCtr || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { landerCtr }) => (
      <>
        {landerCtr !== null
          ? landerCtr == 0
            ? 0
            : landerCtr?.toFixed(3) + "%"
          : null}
      </>
    ),
    width: 100,
  },
  {
    title: <span className="dragHandler">Campaign ID</span>,
    dataIndex: "campaignId",
    key: "campaignId",
    sorter: (a, b) => a.campaignId - b.campaignId,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Date</span>,
    dataIndex: "date",
    key: "date",
    width: 100,
  },
  {
    title: <span className="dragHandler">Report updated</span>,
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (text: string) => {
      const date = new Date(text);
      return <div>{text ? date.toLocaleString() : ""}</div>;
    },
    width: 100,
  },
];
