import React, { useState, useEffect } from "react";
import { Resizable } from "react-resizable";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  IAdsTable,
  IPublisher,
  SectionTableInterface,
  TableDataType,
} from "../../campaigns/types";
import type { ResizeCallbackData } from "react-resizable";
import "./index.css";

type TableProps = {
  scroll?: { x: number; y: number };
  sticky: boolean;
  loading: boolean;
  size?: "small";
  columns: any;
  dataSource: any;
  onChange: (pagination: any, filters: any, sorter: any) => void;
  pagination: any;
};

const ResizableTitle = (
  props: React.HTMLAttributes<any> & {
    onResize: (
      e: React.SyntheticEvent<Element>,
      data: ResizeCallbackData,
    ) => void;
    width: number;
  },
) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const DragColumn: React.FC<{
  initialColumnState: any;
  tableProps: TableProps;
  columnList?: Array<string>;
}> = (props) => {
  const { initialColumnState, tableProps } = props;

  const [columnState, setColumnState] =
    useState<
      ColumnsType<
        TableDataType | IAdsTable | IPublisher | SectionTableInterface
      >
    >(initialColumnState);

  useEffect(() => {
    setColumnState(initialColumnState);
  }, [initialColumnState]);

  const handleResize: Function =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const newColumns = [...columnState];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };
      setColumnState(newColumns);
    };

  const mergeColumns: ColumnsType<
    TableDataType | IAdsTable | IPublisher | SectionTableInterface
  > = columnState.map((col, index) => ({
    ...col,
    onHeaderCell: (
      column: ColumnsType<
        TableDataType | IAdsTable | IPublisher | SectionTableInterface
      >[number],
    ) => ({
      width: column.width,
      onResize: handleResize(index) as React.ReactEventHandler<any>,
    }),
  }));

  return (
    // <ReactDragListView.DragColumn {...dragProps}>
    <Table
      scroll={tableProps.scroll}
      sticky={tableProps.sticky}
      loading={tableProps.loading}
      size={tableProps.size}
      columns={mergeColumns}
      components={{
        header: {
          cell: ResizableTitle,
        },
      }}
      dataSource={tableProps.dataSource}
      onChange={tableProps.onChange}
      pagination={tableProps.pagination}
    />
    // </ReactDragListView.DragColumn>
  );
};

export default DragColumn;
