import { Form, Input, Button, message } from "antd";
import { IInitialValues, IUpdateSource, IUpdateSourceForm } from "./types";
import { sourceApi } from "../../redux/services/sourceApi";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { campaignsApi } from "../../redux/services/campaignsApi";
import { UPDATE_PERMISSION } from "../../utils";
import usePermissionRedirect from "../../hooks/usePermissionValidation";

const SourceUpdateForm: React.FC<{ initialValues: IInitialValues }> = ({
  initialValues,
}) => {
  const params = useParams();
  const navigate = useNavigate();

  const [updateSource, { isSuccess, isError }] =
    sourceApi.useUpdateSourceMutation();
  const { data: user } = campaignsApi.useGetUserQuery();

  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: IUpdateSourceForm) => {
    setLoading(true);
    if (params) {
      const value: IUpdateSource = {
        id: Number(params.id),
        name: values.name,
        url: values.url,
      };
      await updateSource(value);
    }
  };

  const [isAllowToUpdate, setIsAllowToUpdate] = useState<boolean>(
    !!user?.role.permission?.find(
      (permission) => permission.name === UPDATE_PERMISSION,
    )?.name,
  );

  useEffect(() => {
    setIsAllowToUpdate(
      !!user?.role.permission?.find(
        (permission) => permission.name === UPDATE_PERMISSION,
      )?.name,
    );
  }, [user]);

  usePermissionRedirect(user, UPDATE_PERMISSION);

  if (isSuccess) {
    messageApi.open({
      type: "success",
      content: "Success",
    });
    setTimeout(() => {
      setLoading(false);
      navigate("/accounts");
    }, 900);
  } else if (isError) {
    messageApi.open({
      type: "error",
      content: "error",
    });
    setTimeout(() => {
      setLoading(false);
    }, 900);
  }

  return (
    <Form
      name="sourceUpdateForm"
      layout="vertical"
      onFinish={onFinish}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      initialValues={initialValues}
    >
      {contextHolder}
      <Form.Item
        label="Name"
        name="name"
        key="name"
        rules={[
          { required: true, message: "Please enter the name" },
          { min: 2, message: "Name must be at least 2 characters long" },
          { max: 32, message: "Name must not exceed 32 characters" },
        ]}
      >
        <Input disabled={!isAllowToUpdate} />
      </Form.Item>
      {/*<Form.Item*/}
      {/*  rules={[*/}
      {/*    { required: true, message: "Please enter the Funnel Flux Api Key" },*/}
      {/*  ]}*/}
      {/*  label="Funnel Flux Api Key"*/}
      {/*  name="apiKey"*/}
      {/*  key="apiKey"*/}
      {/*>*/}
      {/*  <Input disabled={!isAllowToUpdate} />*/}
      {/*</Form.Item>*/}
      <Form.Item
        rules={[
          { required: true, message: "Please enter the Funnel Flux Url" },
        ]}
        label="Funnel Flux Url"
        name="url"
        key="url"
      >
        <Input disabled={!isAllowToUpdate} />
      </Form.Item>
      <Form.Item>
        <Button
          loading={loading}
          disabled={!isAllowToUpdate}
          type="primary"
          htmlType="submit"
        >
          Update Source
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SourceUpdateForm;
