import axios from "axios";
const url = process.env.REACT_APP_HOST;

const axiosInstance = axios.create({
  baseURL: url,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = accessToken;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {
          const response = await axios.post(
            `${url}/auth/refresh`,
            { refresh_token: refreshToken },
            {
              headers: {
                Authorization: refreshToken,
              },
            },
          );

          const newAccessToken = response.data.access_token;
          const newRefreshToken = response.data.refresh_token;

          localStorage.setItem("accessToken", `bearer ${newAccessToken}`);
          localStorage.setItem("refreshToken", `bearer ${newRefreshToken}`);

          originalRequest.headers.Authorization = newAccessToken;
          return axiosInstance(originalRequest);
        } else {
          window.location.href = "/login";
          localStorage.removeItem("accessToken");
          throw new Error("Refresh token not found");
        }
      } catch (refreshError) {
        window.location.href = "/login";
        localStorage.removeItem("accessToken");
        throw new Error("Failed to refresh access token");
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
