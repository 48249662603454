import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {
  CampaignData,
  ColumnNameEnum,
  CreatePresetInterface,
  GetCurrentUserInterface,
  GetPresetInterface,
  UpdatePresetInterface,
} from "../../../components/campaigns/types";
import { axiosBaseQuery } from "../../../utils/axiosBaseQuery";

export const campaignsApi = createApi({
  reducerPath: "campaignsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_HOST || "http://localhost:8000",
  }),

  endpoints: (build) => ({
    fetchAllCampaigns: build.query<
      CampaignData[],
      {
        date_from?: string;
        date_to?: string;
        filterValue: string;
        accountId?: string;
        filterSourceValue?: string;
        isOptimizationType?: boolean;
        isDailyBudget?: boolean;
      }
    >({
      query: (params) => ({
        url: "/campaigns/",
        method: "get",
        params: {
          date_from: params.date_from,
          date_to: params.date_to,
          filter_value: params.filterValue,
          account_ids: params.accountId,
          filter_source_value: params.filterSourceValue,
          is_optimization_type: params.isOptimizationType,
          is_daily_budget: params.isDailyBudget,
        },
      }),
    }),
    updateCampaigns: build.query<any, boolean>({
      query: (is_fetch) => ({
        url: "/campaigns/update/campaigns",
        method: "get",
      }),
    }),
    createPresets: build.mutation<any, CreatePresetInterface>({
      query: (data) => ({
        url: "/user_preset/create",
        method: "post",
        data: data,
      }),
    }),
    getPresets: build.query<GetPresetInterface[], ColumnNameEnum>({
      query: (data) => ({
        url: "/user_preset/presets",
        method: "get",
        params: {
          table_name: data,
        },
      }),
    }),
    updatePresets: build.mutation<any, UpdatePresetInterface>({
      query: (data) => ({
        url: `/user_preset/${data.preset_id}/update`,
        method: "put",
        data: data.data,
      }),
    }),
    deletePresets: build.mutation<any, number>({
      query: (preset_id) => ({
        url: `/user_preset/${preset_id}/delete`,
        method: "delete",
      }),
    }),
    getCurrentUser: build.query<GetCurrentUserInterface, undefined>({
      query: () => ({
        url: `/users/me`,
        method: "get",
      }),
    }),
    getUser: build.query<GetCurrentUserInterface, void>({
      query: () => ({
        url: `/users/me`,
        method: "get",
      }),
    }),
  }),
});
