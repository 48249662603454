import React, { useEffect, useState } from "react";
import { Select, SelectProps } from "antd";
import DatePickerComponent from "../../components/campaigns/campaignTable/datePicker";
import { LogsFilterInterface } from "../../components/rules/types";
import { debounce } from "lodash-es";
import { format, startOfDay, endOfDay } from "date-fns";

const LogFilter: React.FC<{
  action: string;
  onFilterChange: (data: LogsFilterInterface) => void;
}> = ({ action, onFilterChange }) => {
  const [dateFrom, setDateFrom] = useState<string>();
  const [dateTo, setDateTo] = useState<string>();
  const [statusList, setStatusList] = useState<Array<string>>();
  const [rangePickerDate, setRangePickerDate] = useState();

  const onChangeDateFrom = (date: string) => {
    setDateFrom(date);
  };

  const onChangeDateTo = (date: string) => {
    setDateTo(date);
  };

  const onChangeRangePickerDate = (arg: any) => {
    setRangePickerDate(arg);
  };
  const handleChange = (value: string[]) => {
    setStatusList(value);
  };

  const options: SelectProps["options"] = [
    {
      label: "Success",
      value: "SUCCESS",
    },
    {
      label: "Not Affected",
      value: "NOT AFFECTED",
    },
    {
      label: "Failed",
      value: "FAILED",
    },
  ];
  const [debouncedFilterChange] = useState(() =>
    debounce(
      ({
        action,
        formattedStartDate,
        formattedEndDate,
        statusList,
      }: {
        action: string;
        formattedStartDate: string | undefined;
        formattedEndDate: string | undefined;
        statusList: string[] | undefined;
      }) => {
        onFilterChange({
          action,
          date_from: formattedStartDate,
          date_to: formattedEndDate,
          status: statusList?.join(","),
        });
      },
      500,
    ),
  );

  useEffect(() => {
    let formattedStartDate = undefined;
    let formattedEndDate = undefined;
    if (dateFrom) {
      const date = new Date(dateFrom);
      formattedStartDate = format(
        startOfDay(date),
        "yyyy-MM-dd HH:mm:ss.SSSSSS XXXX",
      );
    }
    if (dateTo) {
      const date = new Date(dateTo);
      formattedEndDate = format(
        endOfDay(date),
        "yyyy-MM-dd HH:mm:ss.SSSSSS XXXX",
      );
    }
    debouncedFilterChange({
      action,
      formattedStartDate,
      formattedEndDate,
      statusList,
    });
  }, [action, dateFrom, dateTo, statusList]);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <div style={{ marginBottom: "10px", marginRight: "5px" }}>
        <DatePickerComponent
          onChangeDateFrom={onChangeDateFrom}
          onChangeDateTo={onChangeDateTo}
          onChangeRangePicker={onChangeRangePickerDate}
          setRangePickerDate={setRangePickerDate}
          rangePickerDate={rangePickerDate}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      </div>
      <div
        style={{
          minWidth: "200px",
          maxWidth: "100%",
          marginBottom: "10px",
        }}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select"
          defaultValue={["SUCCESS", "NOT AFFECTED", "FAILED"]}
          onChange={handleChange}
          options={options}
        />
      </div>
    </div>
  );
};

export default LogFilter;
