import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import { campaignDetailApi } from "./services/campaignDetailApi";
import { campaignsApi } from "./services/campaignsApi";
import { rulesApi } from "./services/rulesApi";
import { sourceApi } from "./services/sourceApi";
import { rulesSlice } from "./reducers/rules";
import { authSlice } from "./reducers/auth";
export const store = configureStore({
  reducer: {
    rules: rulesSlice.reducer,
    auth: authSlice.reducer,
    // campaigns: campaignsSlice.reducer,
    // campaignDetail: campaignSlice.reducer,
    [campaignsApi.reducerPath]: campaignsApi.reducer,
    [campaignDetailApi.reducerPath]: campaignDetailApi.reducer,
    [rulesApi.reducerPath]: rulesApi.reducer,
    [sourceApi.reducerPath]: sourceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      thunkMiddleware,
      campaignsApi.middleware,
      campaignDetailApi.middleware,
      rulesApi.middleware,
      sourceApi.middleware,
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>;
export default RootState;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
