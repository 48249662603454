import { useParams } from "react-router-dom";
import { rulesApi } from "../../../redux/services/rulesApi";
import RuleDetail from "./index";
import { useEffect, useState } from "react";
import { RuleItem, RulesForm } from "../types";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export const EditRule = () => {
  const params = useParams();
  const [rule, setRule] = useState<RulesForm | null>(null);
  const [data, setData] = useState<RuleItem | null>(null);
  const { refetch, status } = rulesApi.useFetchRuleDetailQuery(
    Number(params?.id),
  );

  useEffect(() => {
    refetch().then((response) => response.isSuccess && setData(response.data));
  }, []);

  useEffect(() => {
    if (data) {
      const initialValues: RulesForm = {
        id: data?.id || 0,
        name: data?.name || "",
        interval: data?.dateInterval?.interval || "",
        schedule: data?.schedule.schedule || "",
        specificInterval: data.specificInterval,
        specificDateRange: data.specificInterval
          ? [
              dayjs(data.specificInterval?.dateFrom),
              dayjs(data.specificInterval?.dateTo),
            ]
          : null,
        includeToday: data?.includeToday ?? false,
        conditions: data?.conditions?.map((item) => {
          return {
            condition: item.condition,
            ifValue: item.ifValue,
            value: item.value,
            valueType: item.valueType,
          };
        }),
        campaigns: data?.ruleCampaigns.map((item) => item.campaignId) || [],
        ruleBidModifier: data.publisherBidModifier
          ? {
              bid: data.publisherBidModifier?.bid,
              bidLowerThan: data.publisherBidModifier?.bidLowerThan,
              bidHigherThan: data.publisherBidModifier?.bidHigherThan,
              bidPercentage: data.publisherBidModifier?.bidPercentage,
              ofValue: data.publisherBidModifier?.ofValue,
              ofHigherValue: data.publisherBidModifier.ofHigherValue,
              ofLowerValue: data.publisherBidModifier.ofLowerValue,
              lowerBidPercentage: data.publisherBidModifier.lowerBidPercentage,
              higherBidPercentage:
                data.publisherBidModifier.higherBidPercentage,
            }
          : undefined,
        rulePriority: data.priority,
        isActiveCampaigns: data?.isActiveCampaigns ?? false,
      };

      setRule(initialValues);
    }
  }, [data]);

  return <div>{rule && <RuleDetail initialValues={rule} />}</div>;
};
