import dayjs from "dayjs";

export const getTodayFormatted = (): string => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getDayFormatted = (value: number, isDay: boolean) => {
  const todayDate = dayjs();
  const dateFrom = todayDate.subtract(value, isDay ? "day" : "month").toDate();
  const year = dateFrom.getFullYear();
  const month = String(dateFrom.getMonth() + 1).padStart(2, "0");
  const day = String(dateFrom.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const UPDATE_PERMISSION = "Update";
export const CREATE_PERMISSION = "Create";
export const DELETE_PERMISSION = "Delete";
export const VIEW_PERMISSION = "View";
export const RULE_PERMISSION = "RuleCreateUpdateDelete";
