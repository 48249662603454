import React, { useState, useMemo } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash-es";

const FilterInput: React.FC<{
  onFilterChange: (...args: any) => void;
  campaignId?: string;
  date?: string;
  dateTo?: string;
}> = ({ onFilterChange, campaignId, date, dateTo }) => {
  const [inputValue, setInputValue] = useState("");

  const delayedFilterChange = useMemo(
    () =>
      debounce((param) => {
        if (campaignId && date) {
          onFilterChange({
            id: campaignId,
            date: date,
            date_to: dateTo,
            filterValue: param,
          });
        } else {
          onFilterChange({ date: date, date_to: dateTo, filterValue: param });
        }
      }, 500),
    [onFilterChange],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    delayedFilterChange(value);
  };

  return (
    <Input
      style={{ minWidth: "150px" }}
      value={inputValue}
      onChange={handleChange}
      prefix={<SearchOutlined />}
    />
  );
};

export default FilterInput;
