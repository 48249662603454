import { ColumnsType } from "antd/es/table";
import { SectionTableInterface } from "../../types";
import React from "react";

export const columnsSectionType: ColumnsType<SectionTableInterface> = [
  {
    title: <span className="dragHandler">External Section Id</span>,
    dataIndex: "externalId",
    key: "externalId",
    width: 100,
  },
  {
    title: <span className="dragHandler">Site Name</span>,
    dataIndex: "name",
    key: "name",
    width: 100,
  },
  {
    title: <span className="dragHandler">Impressions</span>,
    dataIndex: "impressions",
    key: "impressions",
    sorter: (a, b) => (a.impressions || 0) - (b.impressions || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Clicks</span>,
    dataIndex: "clicks",
    key: "clicks",
    sorter: (a, b) => (a.clicks || 0) - (b.clicks || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Spent</span>,
    dataIndex: "spent",
    key: "spent",
    sorter: (a, b) => (a.spent || 0) - (b.spent || 0),
    render: (_, { spent }) => <>{spent ? spent.toFixed(4) + "£" : ""}</>,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">ECPC</span>,
    dataIndex: "cpc",
    key: "cpc",
    sorter: (a, b) => (a.cpc || 0) - (b.cpc || 0),
    render: (_, { cpc }) => (
      <>{cpc !== null ? (cpc == 0 ? 0 : "£" + cpc?.toFixed(4)) : null}</>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CPA</span>,
    dataIndex: "cpa",
    key: "cpa",
    sorter: (a, b) => (a.cpa || 0) - (b.cpa || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Total Cpa</span>,
    dataIndex: "totalCpa",
    key: "totalCpa",
    sorter: (a, b) => (a.totalCpa || 0) - (b.totalCpa || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">CTR</span>,
    dataIndex: "ctr",
    key: "ctr",
    sorter: (a, b) => (a.ctr || 0) - (b.ctr || 0),
    render: (_, { ctr }) => (
      <>{ctr !== null ? (ctr == 0 ? 0 : "£" + ctr?.toFixed(4)) : ""}</>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">ROAS</span>,
    dataIndex: "roas",
    key: "roas",
    sorter: (a, b) => (a.roas || 0) - (b.roas || 0),
    render: (_, { roas }) => (
      <>{roas && roas > 0 ? roas?.toFixed(4) + "%" : ""}</>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Total Roas</span>,
    dataIndex: "totalRoas",
    key: "totalRoas",
    sorter: (a, b) => (a.totalRoas || 0) - (b.totalRoas || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Total Conversions</span>,
    dataIndex: "totalConversions",
    key: "totalConversions",
    sorter: (a, b) => (a.totalConversions || 0) - (b.totalConversions || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Conversions</span>,
    dataIndex: "conversions",
    key: "conversions",
    sorter: (a, b) => (a.conversions || 0) - (b.conversions || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Conversion Rate</span>,
    dataIndex: "conversionRate",
    key: "conversionRate",
    sorter: (a, b) => (a.conversionRate || 0) - (b.conversionRate || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">View Conversion Rate</span>,
    dataIndex: "viewConversionRate",
    key: "viewConversionRate",
    sorter: (a, b) => (a.viewConversionRate || 0) - (b.viewConversionRate || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Total Sum Value</span>,
    dataIndex: "totalSumValue",
    key: "totalSumValue",
    sorter: (a, b) => (a.totalSumValue || 0) - (b.totalSumValue || 0),
    render: (_, { totalSumValue }) => (
      <>
        {totalSumValue !== null
          ? totalSumValue == 0
            ? 0
            : "£" + totalSumValue?.toFixed(4)
          : null}
      </>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Sum Value</span>,
    dataIndex: "sumValue",
    key: "sumValue",
    sorter: (a, b) => (a.sumValue || 0) - (b.sumValue || 0),
    render: (_, { sumValue }) => (
      <>
        {sumValue !== null
          ? sumValue == 0
            ? 0
            : "£" + sumValue?.toFixed(4)
          : null}
      </>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">View Sum Value</span>,
    dataIndex: "viewSumValue",
    key: "viewSumValue",
    sorter: (a, b) => (a.viewSumValue || 0) - (b.viewSumValue || 0),
    render: (_, { viewSumValue }) => (
      <>
        {viewSumValue !== null
          ? viewSumValue == 0
            ? 0
            : "£" + viewSumValue?.toFixed(4)
          : null}
      </>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Total Average Value</span>,
    dataIndex: "totalAverageValue",
    key: "totalAverageValue",
    sorter: (a, b) => (a.totalAverageValue || 0) - (b.totalAverageValue || 0),
    render: (_, { totalAverageValue }) => (
      <>
        {totalAverageValue !== null
          ? totalAverageValue == 0
            ? 0
            : "£" + totalAverageValue?.toFixed(4)
          : null}
      </>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Average Value</span>,
    dataIndex: "averageValue",
    key: "averageValue",
    sorter: (a, b) => (a.averageValue || 0) - (b.averageValue || 0),
    render: (_, { averageValue }) => (
      <>
        {averageValue !== null
          ? averageValue == 0
            ? 0
            : "£" + averageValue?.toFixed(4)
          : null}
      </>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">View Average Value</span>,
    dataIndex: "viewAverageValue",
    key: "viewAverageValue",
    sorter: (a, b) => (a.viewAverageValue || 0) - (b.viewAverageValue || 0),
    render: (_, { viewAverageValue }) => (
      <>
        {viewAverageValue !== null
          ? viewAverageValue == 0
            ? 0
            : "£" + viewAverageValue?.toFixed(4)
          : null}
      </>
    ),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Revenue With Upsell</span>,
    dataIndex: "revenueWithUpsell",
    key: "revenueWithUpsell",
    sorter: (a, b) => (a.revenueWithUpsell || 0) - (b.revenueWithUpsell || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Conversions With Upsells</span>,
    dataIndex: "conversionsWithUpsells",
    key: "conversionsWithUpsells",
    sorter: (a, b) =>
      (a.conversionsWithUpsells || 0) - (b.conversionsWithUpsells || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Revenue No Upsells</span>,
    dataIndex: "revenueNoUpsells",
    key: "revenueNoUpsells",
    sorter: (a, b) => (a.revenueNoUpsells || 0) - (b.revenueNoUpsells || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Conversions No Upsell</span>,
    dataIndex: "conversionsNoUpsell",
    key: "conversionsNoUpsell",
    sorter: (a, b) =>
      (a.conversionsNoUpsell || 0) - (b.conversionsNoUpsell || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Lander Views</span>,
    dataIndex: "landerViews",
    key: "landerViews",
    sorter: (a, b) => (a.landerViews || 0) - (b.landerViews || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Lander Clicks</span>,
    dataIndex: "landerClicks",
    key: "landerClicks",
    sorter: (a, b) => (a.landerClicks || 0) - (b.landerClicks || 0),
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Lander CTR</span>,
    dataIndex: "landerCtr",
    key: "landerCtr",
    sorter: (a, b) => (a.landerCtr || 0) - (b.landerCtr || 0),
    sortDirections: ["ascend", "descend"],
    render: (_, { landerCtr }) => (
      <>
        {landerCtr !== null
          ? landerCtr == 0
            ? 0
            : landerCtr?.toFixed(3) + "%"
          : null}
      </>
    ),
    width: 100,
  },
  {
    title: <span className="dragHandler">Campaign ID</span>,
    dataIndex: "campaignId",
    key: "campaignId",
    sorter: (a, b) => a.campaignId - b.campaignId,
    sortDirections: ["ascend", "descend"],
    width: 100,
  },
  {
    title: <span className="dragHandler">Date</span>,
    dataIndex: "date",
    key: "date",
    width: 100,
  },
  {
    title: <span className="dragHandler">Report updated</span>,
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (text: string) => {
      const date = new Date(text);
      return <div>{text ? date.toLocaleString() : ""}</div>;
    },
    width: 100,
  },
];
