import { Button, Col, Dropdown, MenuProps, message, Row, Space } from "antd";
import RulesTable from "./rulesTable";
import {
  CaretRightOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DollarOutlined,
  DollarTwoTone,
  DownOutlined,
  MoneyCollectOutlined,
  PauseOutlined,
  PlusOutlined,
  SyncOutlined,
  ThunderboltFilled,
  ThunderboltOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import { rulesApi } from "../../redux/services/rulesApi";
import React, { useContext, useEffect, useState } from "react";
import { campaignsApi } from "../../redux/services/campaignsApi";
import { RULE_PERMISSION } from "../../utils";
import { WebsocketContext } from "../../shared/context/websocketContext";
import { CampaignTaskInterface, RunningRuleInterface } from "./types";
import useAuth from "../../hooks/useAuth";

const Rules = () => {
  const navigate = useNavigate();
  const { selectedRule } = useAppSelector((item) => item.rules);
  const [changeRuleStatus] = rulesApi.useChangeRuleStatusMutation();
  const { data, status, refetch } = rulesApi.useFetchAllRulesQuery("");
  const [updateCampaigns, { status: updateCampaignStatus }] =
    campaignsApi.useLazyUpdateCampaignsQuery();
  const [messageApi, contextHolder] = message.useMessage();
  const { data: user } = campaignsApi.useGetUserQuery();
  const websocket = useContext(WebsocketContext);

  const handleUpdateCampaigns = () => {
    updateCampaigns(true);
  };
  const [campaignsTaskStatus, setCampaignsTaskStatus] = useState<boolean>();

  const handleSuccess = () => {
    messageApi.open({
      type: "success",
      content: "The task for updating campaigns is running",
    });
  };

  useEffect(() => {
    if (updateCampaignStatus == "fulfilled") {
      handleSuccess();
    }
  }, [updateCampaignStatus]);

  useEffect(() => {
    refetch();
  }, []);

  const [rulePermission, setRulePermission] = useState<boolean>(
    !!user?.role.permission?.find(
      (permission) => permission.name === RULE_PERMISSION,
    )?.name,
  );

  useAuth(user?.accessToken);

  useEffect(() => {
    setRulePermission(
      !!user?.role.permission?.find(
        (permission) => permission.name === RULE_PERMISSION,
      )?.name,
    );
    setCampaignsTaskStatus(user?.tenant.is_active_update_campaigns);
  }, [user]);

  const [runningRule, setRunningRule] = useState<RunningRuleInterface>();
  useEffect(() => {
    websocket?.on("send_message", (data: RunningRuleInterface) => {
      setRunningRule(data);
    });
    websocket?.on(
      "update_campaign_task_event",
      (data: CampaignTaskInterface) => {
        setCampaignsTaskStatus(data.status);
      },
    );
  }, [websocket]);

  const actionItmes: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <span
          onClick={() => {
            if (selectedRule) {
              changeRuleStatus({
                ruleIds: selectedRule,
                action: "active",
              }).then(() => refetch());
            }
          }}
        >
          <CaretRightOutlined /> Active
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span
          onClick={() => {
            if (selectedRule) {
              changeRuleStatus({
                ruleIds: selectedRule,
                action: "deactive",
              }).then(() => refetch());
            }
          }}
        >
          <PauseOutlined /> Deactive
        </span>
      ),
    },
  ];
  const rulesItmes: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <span
          onClick={() => {
            navigate("/rule/create", {
              state: {
                action: "Change publisher bid modifier",
              },
            });
          }}
        >
          <ThunderboltOutlined /> Change publisher bid modifier
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span
          onClick={() => {
            navigate("/rule/create", {
              state: {
                action: "Pause ad",
              },
            });
          }}
        >
          <PauseOutlined /> Pause ad
        </span>
      ),
    },
    {
      key: "3",
      label: (
        <span
          onClick={() => {
            navigate("/rule/create", {
              state: {
                action: "Block publisher",
              },
            });
          }}
        >
          <CloseOutlined /> Block publisher
        </span>
      ),
    },
    {
      key: "4",
      label: (
        <span
          onClick={() => {
            navigate("/rule/create", {
              state: {
                action: "Block section",
              },
            });
          }}
        >
          <CloseCircleOutlined /> Block section
        </span>
      ),
    },
    {
      key: "5",
      label: (
        <span
          onClick={() => {
            navigate("/rule/create", {
              state: {
                action: "Change section bid modifier",
              },
            });
          }}
        >
          <ThunderboltFilled /> Change section bid modifier
        </span>
      ),
    },
    {
      key: "6",
      label: (
        <span
          onClick={() => {
            navigate("/rule/create", {
              state: {
                action: "Change campaign bid",
              },
            });
          }}
        >
          <DollarOutlined /> Change campaign bid
        </span>
      ),
    },
    {
      key: "7",
      label: (
        <span
          onClick={() => {
            navigate("/rule/create", {
              state: {
                action: "Change campaign budget",
              },
            });
          }}
        >
          <WalletOutlined /> Change campaign budget
        </span>
      ),
    },
  ];
  return (
    <div>
      {contextHolder}
      <Row style={{ display: "flex" }}>
        <Col style={{ marginRight: "10px", marginBottom: "10px" }}>
          <Dropdown
            disabled={!rulePermission}
            trigger={["click"]}
            menu={{ items: actionItmes }}
          >
            <Button disabled={!rulePermission}>
              <Space>
                Action
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Col>
        <Col style={{ marginRight: "10px", marginBottom: "10px" }}>
          <Dropdown
            disabled={!rulePermission}
            trigger={["click"]}
            menu={{ items: rulesItmes }}
            arrow
          >
            <Button disabled={!rulePermission}>
              New Rule
              <PlusOutlined />
            </Button>
          </Dropdown>
        </Col>
        <Col>
          <Button
            disabled={!rulePermission}
            onClick={handleUpdateCampaigns}
            loading={updateCampaignStatus == "pending"}
          >
            Update campaigns{" "}
            {campaignsTaskStatus ? <SyncOutlined spin /> : null}
          </Button>
        </Col>
      </Row>
      <div>
        <RulesTable
          data={data ? data : []}
          status={status}
          refetch={refetch}
          userRulePermission={rulePermission}
          runningRule={runningRule}
        />
      </div>
    </div>
  );
};

export default Rules;
