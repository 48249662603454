import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  message,
  Modal,
  Divider,
  Typography,
  Form,
  Select,
  Popconfirm,
} from "antd";
import { Checkbox } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import {
  SearchOutlined,
  DragOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { campaignsApi } from "../../redux/services/campaignsApi";
import { CreatePresetInterface, GetPresetInterface } from "../campaigns/types";
import axiosInstance from "../../utils/Interceptor";

const { Text } = Typography;
interface Item {
  label: string;
  value: string;
}

const reorder = (
  list: Item[],
  startIndex: number,
  endIndex: number,
): Item[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): React.CSSProperties => ({
  userSelect: "none",
  padding: grid,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "lightgray" : "white",
  border: "1px solid lightgray",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "space-between",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? "white" : "white",
  marginRight: "20px",
});

const CreatePreset: React.FC<{
  tableName: string;
  columns?: Array<{ label: string; value: string }>;
  presets?: GetPresetInterface[];
  trigger?: () => void;
}> = ({ tableName, columns, presets, trigger }) => {
  const [createPreset, { status }] = campaignsApi.useCreatePresetsMutation();
  const [updatePresets, { status: updatePresetStatus }] =
    campaignsApi.useUpdatePresetsMutation();

  const [searchColumn, setSearchValue] = useState<string>();
  const [columnList, setColumnList] = useState(columns);

  const [selectedList, setSelectedList] = useState<CheckboxValueType[]>(
    columns ? columns.map((item) => item.value) : [],
  );
  const [state, setState] = useState(columns);

  const [messageApi, contextHolder] = message.useMessage();
  const defaultPreset = [
    {
      id: 0,
      name: "Create preset",
      table_name: "",
      column_settings: "",
    },
  ] as GetPresetInterface[];

  const [allPreset, setAllPreset] = useState<CreatePresetInterface[] | []>(
    defaultPreset,
  );
  const [selectedPreset, setSelectedPreset] = useState<
    GetPresetInterface | undefined
  >(defaultPreset[0]);
  const [searchPreset, setSearchPreset] = useState<string>();

  const [form] = Form.useForm();

  const successCreate = () => {
    messageApi.open({
      type: "success",
      content: "Success",
    });
  };

  const successUpdate = () => {
    messageApi.open({
      type: "success",
      content: "Success",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Error",
    });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = async (values: { name: string }) => {
    const columnSettingsJSON = JSON.stringify(state);

    if (searchPreset == "Create preset") {
      await createPreset({
        name: values.name,
        table_name: tableName,
        column_settings: columnSettingsJSON,
      } as CreatePresetInterface);
    } else {
      if (selectedPreset?.id) {
        await updatePresets({
          preset_id: selectedPreset?.id,
          data: {
            name: values.name,
            table_name: tableName,
            column_settings: columnSettingsJSON,
          },
        });
      }
    }
  };

  const InitialState = () => {
    form.setFieldValue("name", undefined);
    setSelectedPreset(defaultPreset[0]);
    setSelectedList(columns ? columns.map((item) => item.value) : []);
    setSearchPreset("Create preset");
    setState(columns);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    InitialState();
  };

  useEffect(() => {
    if (status === "fulfilled") {
      successCreate();
      trigger && trigger();
    }
    if (updatePresetStatus === "fulfilled") {
      successUpdate();
      trigger && trigger();
    }

    if (status === "rejected" || updatePresetStatus === "rejected") {
      error();
    }

    handleCloseModal();
  }, [status, updatePresetStatus]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    handleCloseModal();
  };

  const handleOnChangeSearch = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (searchColumn && columns) {
      const filteredColumns = columns.filter((item) =>
        item.value.toLowerCase().includes(searchColumn.toLowerCase()),
      );
      setColumnList(filteredColumns);
    } else {
      setColumnList(columns);
    }
  }, [searchColumn]);

  const [initialPresetState, setInitialPresetState] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >();

  useEffect(() => {
    if (!state || !columns) {
      return;
    }
    if (selectedPreset?.name === "Create preset") {
      const newState = state.filter((item) => {
        return selectedList.includes(item.value);
      });
      selectedList.forEach((selectedValue) => {
        if (!newState.some((item) => item.value === selectedValue)) {
          newState.push({
            label:
              columns.find((item) => item.value == selectedValue.toString())
                ?.label ?? "",
            value: selectedValue.toString(),
          });
        }
      });
      setState(newState);
    } else {
      if (selectedPreset) {
        if (searchPreset !== "Create preset" && initialPresetState) {
          const newState = [...initialPresetState];
          selectedList.forEach((selectedValue) => {
            const existsInNewState = newState.some(
              (item) => item.value === selectedValue,
            );
            if (!existsInNewState && selectedValue && initialPresetState) {
              newState.push({
                label:
                  columns.find(
                    (item: { label: string; value: string }) =>
                      item.value == selectedValue.toString(),
                  )?.label ?? "",
                value: selectedValue.toString(),
              });
            }
          });
          const filteredNewState = newState.filter((item) =>
            selectedList.includes(item.value),
          );
          setState(filteredNewState);
        }
      }
    }
  }, [selectedList, initialPresetState]);

  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }
    if (state) {
      const items: Item[] = reorder(
        state,
        result.source.index,
        result.destination.index,
      );
      setState(items);
    }
  };

  const handleOnChangeCheckbox = (event: CheckboxChangeEvent) => {
    const field = event.target.value;
    const checked = event.target.checked;
    setSelectedList((prevSelectedList) => {
      if (checked) {
        return prevSelectedList.concat(field);
      } else {
        return prevSelectedList.filter((item) => item !== field);
      }
    });
  };

  useEffect(() => {
    if (!presets || !searchPreset) {
      return;
    }
    if (searchPreset == "Create preset") {
      form.setFieldValue("name", undefined);
      setSelectedPreset(defaultPreset[0]);
      return;
    }
    const findPreset = presets.find((item) => item.name == searchPreset);
    setSelectedPreset(presets && findPreset);

    const settingsArray: { label: string; value: string }[] = JSON.parse(
      findPreset?.column_settings ?? "",
    );
    const selected: string[] = settingsArray.map(
      (item: { label: string; value: string }) => item.value,
    );
    form.setFieldValue("name", findPreset?.name);
    setSelectedList(selected);
  }, [searchPreset]);

  useEffect(() => {
    setInitialPresetState(() => {
      if (searchPreset && searchPreset !== "Create preset" && selectedPreset) {
        const columnSettingsJSON = JSON.parse(selectedPreset?.column_settings);
        return columnSettingsJSON.map(
          (item: { label: string; value: string }) => ({
            label: item.label,
            value: item.value,
          }),
        );
      }
    });
  }, [selectedPreset]);

  useEffect(() => {
    if (presets) {
      setAllPreset([...defaultPreset, ...presets]);
    }
  }, [presets]);

  const onChangeSelectedPreset = (value: string) => {
    setSearchPreset(value);

    if (value === "Create preset") {
      InitialState();
    }
  };

  const handleDeletePreset = async (preset_id: number) => {
    await axiosInstance
      .delete(`/user_preset/${preset_id}/delete`)
      .then((data) => {
        if (data.status == 200) {
          InitialState();
          trigger && trigger();
        }
      });
  };

  return (
    <>
      <Button
        type="primary"
        draggable
        style={{ width: "150px" }}
        onClick={showModal}
      >
        Preset settings
      </Button>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              margin: "0 0 -25px 0",
            }}
          >
            <Text
              style={{ opacity: "0.8", fontSize: "16px", marginBottom: "20px" }}
            >
              Column settings
            </Text>
            <div style={{ paddingRight: "20px" }}>
              <Select
                style={{ minWidth: "200px" }}
                showSearch
                placeholder="Select a preset"
                onChange={onChangeSelectedPreset}
                value={selectedPreset?.name}
                options={
                  allPreset
                    ? allPreset.map((item) => ({
                        label: item.name,
                        value: item.name,
                      }))
                    : []
                }
              />
            </div>
          </div>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        style={{
          minWidth: "600px",
          minHeight: "200px",
        }}
        footer={[
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              key="delete"
              type="primary"
              danger
              disabled={
                selectedPreset && selectedPreset.name === "Create preset"
              }
            >
              {selectedPreset && (
                <Popconfirm
                  title="Delete the preset"
                  description="Are you sure to delete this preset?"
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                  onConfirm={() => handleDeletePreset(selectedPreset?.id)}
                >
                  Delete
                </Popconfirm>
              )}
            </Button>
            <div>
              <Button key="cancel" htmlType="button" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                key="submit"
                form="myForm"
                type="primary"
                htmlType="submit"
              >
                {selectedPreset?.name === "Create preset"
                  ? "Create preset"
                  : "Update preset"}
              </Button>
            </div>
          </div>,
        ]}
      >
        <div
          style={{
            maxWidth: "100%",
            paddingRight: "20px",
            marginBottom: "20px",
          }}
        >
          <Divider style={{ marginRight: "20px" }} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                maxWidth: "100%",
                paddingRight: "20px",
                marginBottom: "20px",
              }}
            >
              <Input
                style={{
                  borderRadius: "20px",
                }}
                value={searchColumn}
                onChange={(e) => handleOnChangeSearch(e.target.value)}
                prefix={<SearchOutlined />}
                placeholder={"Search..."}
              />
            </div>
            <Text
              style={{ opacity: "0.8", fontSize: "16px", marginBottom: "20px" }}
            >
              Columns visibility
            </Text>
            <div
              style={{
                maxHeight: "250px",
                maxWidth: "200px",
                overflowY: "auto",
              }}
            >
              {columns && (
                <Checkbox.Group value={selectedList.map((item) => item)}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {columnList &&
                      columnList.map((item) => {
                        return (
                          <Checkbox
                            onChange={(e) => handleOnChangeCheckbox(e)}
                            value={item.value}
                            checked={!!item.value}
                          >
                            {item.label}
                          </Checkbox>
                        );
                      })}
                  </div>
                </Checkbox.Group>
              )}
            </div>
          </div>
          <Divider type="vertical" style={{ height: "350px" }} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ paddingRight: "20px" }}>
              <Form
                id="myForm"
                layout="vertical"
                form={form}
                name="control-ref"
                onFinish={onFinish}
              >
                {contextHolder}
                <Form.Item
                  style={{ marginBottom: "20px" }}
                  name="name"
                  rules={[
                    { required: true, message: "this field is required" },
                  ]}
                >
                  <Input
                    placeholder={"name"}
                    style={{
                      borderRadius: "20px",
                      marginRight: "20px",
                      padding: "4px 11px",
                    }}
                  />
                </Form.Item>
              </Form>
            </div>
            <Text
              style={{ opacity: "0.8", fontSize: "16px", marginBottom: "20px" }}
            >
              Columns order
            </Text>
            <div
              style={{
                maxHeight: "250px",
                maxWidth: "250px",
                overflowY: "auto",
              }}
            >
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot): JSX.Element => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {state &&
                        state.map((item, index) => (
                          <Draggable
                            key={item.value}
                            draggableId={item.value}
                            index={index}
                          >
                            {(provided, snapshot): JSX.Element => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}
                              >
                                {item.label}{" "}
                                <div style={{ marginTop: "1px" }}>
                                  <DragOutlined />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
        <Divider />
      </Modal>
    </>
  );
};

export default CreatePreset;
