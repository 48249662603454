import { Socket, io } from "socket.io-client";
import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import useAuth from "../../hooks/useAuth";

export const WebsocketContext = createContext<Socket | null>(null);
const WS_URL = process.env.REACT_APP_WS_URL;

export const WebsocketProvider = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const [ws, setWs] = useState<null | Socket>(null);
  const accessToken = useAuth();

  const resetWsConnection = useCallback(() => {
    ws?.close();
    if (accessToken) {
      const socket = io(`${WS_URL}`, {
        auth: [accessToken],
        transports: ["websocket"],
      });
      setWs(socket);
    }
  }, [accessToken]);

  useEffect(() => {
    resetWsConnection();
  }, [resetWsConnection]);

  useEffect(() => {
    return () => {
      ws?.close();
    };
  });
  return (
    <WebsocketContext.Provider value={ws}>{children}</WebsocketContext.Provider>
  );
};
